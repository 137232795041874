import {
    Box,
    Button,
    CircularProgress,
    Snackbar,
    TextField,
    Typography,
  } from "@mui/material";
  import React from "react";
  import { useEffect, useState } from "react";

  import { useNavigate } from "react-router-dom";
  import { baseURL } from "../Config/config";
  
  import { Link } from "@mui/material";
  import Modal from "@mui/material/Modal";
  import { Paper } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import Projectcolors from "../Utils/Colors";
  
  import Selexlogo from "../images/de3.png";
  import matrix from "../images/matrix.png";
  import selexmathimg from "../images/SelexMath-2.png";
  import SubNavbar from "./CommonC/SubNavbar";
  import { Alert } from "@mui/material";
  import BgContainer2 from "./CommonC/BgContainer2";
  import Grow from '@mui/material/Grow';
  import './YourComponent.css';
  import SelexMathNavbar from "./CommonC/SelexMathNavbar";

  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 700,
    bgcolor: "white",
    border: "2px solid #000",
    p: 4,
    overflow: "scroll",
    display: "block",
  };
  const stylec = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 450,
    bgcolor: "white",
    border: "2px solid #000",
    p: 4,
    display: "block",
  };



  
  const SelectSelexMath = ({handleNavigate}) => {
    const navigate = useNavigate();
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [hoveredmathmodel, sethoveredmathmodel] = useState(false);
    const [hoveredrom, sethoveredrom] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openc, setOpenc] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [state, setState] = React.useState({
      isOpen: false,
      vertical: "top",
      horizontal: "center",
    });
    const [checked, setChecked] = React.useState(false);
   
  
    const { vertical, horizontal, isOpen } = state;
  
    const handleOpenc = () => setOpenc(true);
    const handleClosec = () => setOpenc(false);
  
    const handleAuth = async () => {
      setLoading(true);
      const body = {
        username,
        password,
      };
  
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      };
      try {
        let result = await fetch(`${baseURL}/login`, options);
        result = await result.json();
        if (result.token) {
          handleNavigate(result.token)
          navigate("/home");
          setLoading(false);
        }
        setLoading(false);
        handleSnackbar(true);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
  
      return;
    };
  
    const handleSnackbar = (value) => {
      setState({ ...state, isOpen: value });
    };

    React.useEffect(() => {
      setChecked(true);
    }, [])
  
    return (
      <div
        style={{
          height: window.screen.height,
        }}
      >
        <BgContainer2>
        <SelexMathNavbar />

  
          <br />
          <div
            style={{
              backgroundColor: "rgba(255,255,255)",
              //flexDirection: "row",
              borderRadius: "10px",
              //border: "1px solid #0078C9",
              alignItems: "center",
              marginTop: "9%",
              marginLeft: "35%",
              height:'250px',
              width:'520px',
              padding:'20px'
            }}
          >
            <Typography sx={{fontSize:16,color:"#0078C9",fontWeight:"bold"}}>Select a SelexMath Solution : </Typography>
          <div
            style={{
              backgroundColor: "rgba(255,255,255)",
              display: "flex",
              flex: 1,
              flexDirection: "row",
              borderRadius: "10px",
              //border: "1px solid #0078C9",
              alignItems: "center",
              //marginTop: "9%",
              //marginLeft: "35%",
              height:'150px',
              width:'450px',
              padding:'10px'
            }}
          >
            <Grow in={checked} timeout={2000}>
            <div
              style={{
                backgroundColor: "rgba(255,255,255)",
                display: "flex",
                padding: "2px 70px 2px 70px",
                marginTop: "48px",
                flexDirection: "column",
                borderRadius: "10px",
                border: "1px solid #0078C9",
                marginBottom: "10px",
                marginLeft:"6%",
                position: "relative",
                height:'140px',
                width:'220px'

              }}
            >
              <div style={{ position: "absolute", left: "10px",alignItems:"center" }} className="zoom">
              <Link
                sx={{
                  fontSize: "11px",
                  marginTop: "2px",
                  color: Projectcolors.primary,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/selexmath")}
              >
                <img
                  style={{
                    height: "60px",
                    width: "135px",
                    marginLeft:"11%",
                    marginTop: "7%",
                    objectFit: "contain",
                  }}
                  src={Selexlogo}
                  onMouseEnter={() => sethoveredmathmodel(true)}
                  onMouseLeave={() => sethoveredmathmodel(false)}
                />
                </Link>
                <Typography sx={{marginLeft:"12%",fontSize:"13px",marginTop:1}}>Mathematical Modeling</Typography>

              </div>
  
             
  
              
              
  
             
            </div>
            </Grow>
            <Grow in={checked} timeout={2000}>
            <div
              style={{
                backgroundColor: "rgba(255,255,255)",
                display: "flex",
                padding: "2px 20px 2px 2px",
                marginTop: "48px",
                flexDirection: "column",
                borderRadius: "10px",
                border: "1px solid #0078C9",
                marginBottom: "10px",
                marginLeft:"3%",
                position: "relative",
                height:'140px',
                width:'240px'

              }}
            >
                              <div style={{ position: "absolute", left: "10px",alignItems:"center" }} className="zoom">

                  <Link
                sx={{
                  fontSize: "11px",
                  marginTop: "2px",
                  color: Projectcolors.primary,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/selexanalysis")}
              >
                <img
                  style={{
                    height: "65px",
                    width: "135px",
                    marginLeft:"11%",
                    marginTop: "7%",
                    objectFit: "contain",
                  }}
                  src={matrix}
                  onMouseEnter={() => sethoveredrom(true)}
                  onMouseLeave={() => sethoveredrom(false)}
                />
                </Link>
                <Typography sx={{marginLeft:"12%",fontSize:"13px",marginTop:1}}>Lean Model Dynamics</Typography>

              </div>
              </div>
              </Grow>
          </div>
          </div>

          {hoveredmathmodel && (
                  <div
                    style={{
                      position: "absolute",
                      top: "10%", // Adjust this based on your layout
                      left: "2%", // Extreme left of the screen
                      backgroundColor: "white",
                      border: "1px solid #ccc",
                      padding: "10px",
                      borderRadius: "5px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                      zIndex: 1000, // Ensure it appears on top
                      width:300,
                      height:700
                    }}
                  >
                    <Typography sx={{ fontSize: 16, color: Projectcolors.primary }}>
                    Mathematical Modeling
                    </Typography>
                    <Typography sx={{ fontSize: 14, color: "#333" }}>
                    
                    
A key component of the SelexSuite by Emad Gebesy, is a state-of-the-art mathematical modeling application designed to address complex engineering challenges in the Oil & Gas and Energy sectors. This powerful tool provides robust capabilities for developing, simulating, and optimizing mathematical models, enabling engineers and researchers to solve intricate problems with precision and efficiency for ODE (Ordinary Differential Equation) and PDE (Partial Differential Equation).
 SelexMath supports various modeling approaches, including first-principles equations, empirical correlations, and hybrid methods that integrate traditional modeling with machine learning. Its versatile platform allows for seamless integration with simulation tools like Aspen HYSYS, enhancing workflows and enabling accurate predictions of system behavior. With its user-friendly interface and extensive library of advanced algorithms, SelexMath empowers organizations to accelerate innovation, improve process performance, and make data-driven decisions for sustainability and operational excellence.
                    </Typography>
                  </div>
                )}

                {hoveredrom && (
                        <div
                          style={{
                            position: "absolute",
                            top: "10%", // Adjust this based on your layout
                            left: "2%", // Extreme left of the screen
                            backgroundColor: "white",
                            border: "1px solid #ccc",
                            padding: "10px",
                            borderRadius: "5px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                            zIndex: 1000, // Ensure it appears on top
                            width:360,
                            height:700
                          }}
                        >
                          <Typography sx={{ fontSize: 16, color: Projectcolors.primary }}>
                          The Lean Model Dynamics (LMD) 
                          </Typography>
                          <Typography sx={{ fontSize: 14, color: "#333" }}>
                          
                          SelexMath is an advanced feature designed to simplify complex systems into manageable representations while preserving essential dynamics and accuracy. LMD enables engineers to reduce computational loads and enhance simulation speed, making it ideal for real-time analysis, optimization, and control applications. By leveraging techniques like proper orthogonal decomposition (POD) and surrogate modeling, SelexMath provides efficient solutions for large-scale problems without compromising fidelity, empowering users to achieve faster decision-making and more effective resource allocation.
Discrete and Stochastic Analysis in SelexMath
SelexMath offers two powerful analysis methodologies to address diverse engineering challenges:
	•	Discrete Analysis: This approach involves solving deterministic systems where inputs and parameters are fixed and well-defined. It is particularly suited for scenarios requiring precise solutions, such as process optimization, equipment sizing, and steady-state system evaluations.
	•	Stochastic Analysis: This method accounts for uncertainty and variability in system parameters, leveraging probabilistic models to analyze a range of possible outcomes. Stochastic analysis is invaluable for risk assessment, sensitivity studies, and evaluating systems under uncertain conditions, enabling users to develop robust solutions with confidence in real-world applications.
                
                          </Typography>
                        </div>
                      )}
  
          
        </BgContainer2>
      </div>
    );
  };
  
  export default SelectSelexMath;
  