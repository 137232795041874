import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Modal from "./CommonC/Modal";
import Projectcolors from "../Utils/Colors";
import Navbar from "./CommonC/Navbar";
import { makeStyles, withStyles } from "@mui/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { baseURL } from "../Config/config";
import Optimize from "../images/logooptimizegs.png";
import bg from "../images/AI.jpg";
import ShareIcon from "@mui/icons-material/Share";
import { useNavigate } from "react-router-dom";
import GlobalLoader from "./CommonC/GlobalLoader";
import BackgroundContainer from "./CommonC/BackgroundContainer";
import BgContainer2 from "./CommonC/BgContainer2";
import ShareProjectModal from "./CommonC/ShareProjectModal";
import EditModal from "./CommonC/EditModal";

import { useSelector, useDispatch } from "react-redux";
import { create } from "../Store/projectPermission/permssionReducer";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {  CircularProgress } from "@mui/material";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableRow: {
    height: 1,
  },
  tableCell: {
    padding: "0px 16px",
  },
  root: {
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    minHeight: "1px",
    padding: "0",
  },
});

const tableHeaders = [
  "Project Name",
  "Last Modified",
  "Edit",
  "Copy",
  "Delete",
  "Share",
];

function NewProject() {
  const [isModal, setIsModal] = React.useState(false);
  const [iseditModal, setIseditModal] = React.useState(false);
  const navigate = useNavigate();

  const [projectDetails, setProjectDetails] = React.useState();
  const [getLoader, setGetLoader] = React.useState(false);
  const [shareModal, setShareModal] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [shareProjectName, setShareProjectName] = React.useState("");
  const [shareProjectID, setShareProjecID] = React.useState("");
  const [editProjectName, setEditProjectName] = React.useState("");
  const [editProjectID, setEditProjectID] = React.useState("");
  const [editClientName, setEditClientName] = React.useState("");
  const [editObjective, setEditObjective] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
  


  const dispatch = useDispatch();
  const classes = useStyles();
  const cart = useSelector((state) => state.cart);
  const [open, setOpen] = React.useState(false);
  const [timer, setTimer] = React.useState(null);
  const [message, setmessage] = React.useState("");


  const handleClickOpen = () => {
    setOpen(true);
    setTimer(
      setTimeout(() => {
          setOpen(false);
      }, 2500)
  );

  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleeditClose = () => {
    setIseditModal(false);
  };
  const handleeditOpen = () => {
    setIseditModal(true);
  };

  React.useEffect(() => {
    getAllProjects();
  }, []);


  const getProjectDetails = async () => {
    // setGetLoader(true);
    const token = localStorage.getItem("usertoken");
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json", token: token },
    };

    try {
      const result = await fetch(`${baseURL}/projectaccessdetails`, options);
      let res = await result.json();
      if (!res.code) {
        // setGetLoader(false);
        return;
      }
      // setGetLoader(false);
      // dispatch(create(res.projectDetails));
    } catch (error) {
      // setGetLoader(false);
      console.log("error", error);
    }
  };

  const getAllProjects = async (id) => {
    setGetLoader(true);
    const token = localStorage.getItem("usertoken");
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json", token: token },
    };

    try {
      const result = await fetch(`${baseURL}/allprojects`, options);
      let res = await result.json();
      if (!res.code) {
        setGetLoader(false);
        return;
      }
      // getProjectDetails();
      setGetLoader(false);
      setProjectDetails([...res.data]);
    } catch (error) {
      setGetLoader(false);
      console.log("error", error);
    }
  };

  const StyledTableRow = withStyles((theme) => ({
    root: {
      height: 5,
    },
  }))(TableRow);

  const StyledTableCell = withStyles((theme) => ({
    root: {
      padding: "0px 16px",
    },
  }))(TableCell);

  const deleteProject = async (id) => {
    let body = {
      projectID: id,
    };

    const token = localStorage.getItem("usertoken");

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json", Token: token },
      body: JSON.stringify(body),
    };
    try {
      let result = await fetch(`${baseURL}/deleteproject`, options);
      result = await result.json();
      getAllProjects();
    } catch (error) {
      console.log("error", error);
    }
  };


  const copyProject = async (id) => {
    let body = {
      projectID: id,
    };

    const token = localStorage.getItem("usertoken");

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json", Token: token },
      body: JSON.stringify(body),
    };
    try {
      let result = await fetch(`${baseURL}/copyproject`, options);
      result = await result.json();
      getAllProjects();
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleModal = () => {
    return setIsModal(!isModal);
  };



  

  const toggleShareModal = (projectName, projectID) => {
    setShareModal(!shareModal);
    setShareProjecID(projectID);
    setShareProjectName(projectName);
  };
  console.log(iseditModal)
  const toggleEditModal = (projectName, projectID) => {
    console.log("inside")

    fetch(
      `${baseURL}/projecteditdetails?projectID=${projectID}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === 1) {
          console.log(data)
          setEditProjectName(data.projectName);
          setEditClientName(data.clientname);
          setEditObjective(data.objective);

          setEditProjectID(projectID)
          handleeditOpen()
        } else {
          console.log("");
        }
      })
      .catch((err) => {
        setGetLoader(false);
        console.log(err);
      });
  };

  const handleEditProject = async () => {
    const token = localStorage.getItem("usertoken");
    console.log("editProjectID",editProjectID)
    localStorage.setItem("projectID",editProjectID);
    localStorage.setItem("projectName",editProjectName);
    localStorage.setItem("loaded",true);


    const body = {
      editProjectID,
    editProjectName,
    editClientName,
    editObjective,
    time: new Date(),
    // projectID,
    
  };

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json", token: token },
      body: JSON.stringify(body),
    };

    try {
      let res = await fetch(`${baseURL}/editproject`, options);
      res = await res.json();
      if (res.code === 1) {
        setIsLoading(false);
        navigate("/newproject", { state: { loaded: true, ProjectID: editProjectID,projectName: editProjectName } });
        return;

      }
      
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleShareProject = async (value) => {
    const token = localStorage.getItem("usertoken");

    const body = {
      email,
      shareProjectName,
      shareProjectID,
      editAccess: value,
    };

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json", token: token },
      body: JSON.stringify(body),
    };

    try {
      let res = await fetch(`${baseURL}/shareproject`, options);
      res = await res.json();
      if (res.code === 1) {
        toggleShareModal();
        handleClickOpen();
        setmessage("Project Shared Successfully")
        return;
      }
      else if(res.code === 0){
        toggleShareModal();
        handleClickOpen();
        setmessage(res.message);
      }
      else if (res.code === 2){
        toggleShareModal();
        handleClickOpen();
        setmessage(res.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleNavigation = (projectID,projectName) => {
    navigate("/newproject", {
      state: {
        ProjectID: projectID,
        projectName:projectName,
        loaded: true,
      },
    });
  };

  console.log("editprname",editProjectName,editClientName,editObjective,editProjectID)

  //  onClick={()=>navigate("/newproject",{ state : {ProjectID:item.projectID,loaded:true}})}

  return (
    <div
      style={{
        height: window.screen.height,
      }}
    >
      <Navbar />
      <BgContainer2>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      
      >
        <DialogTitle id="alert-dialog-title" sx={{color:Projectcolors.primary,fontSize:"13px"}}>
          {message}
        </DialogTitle>
          </Dialog>
        <Box
          m="auto"
          mt={10}
          sx={{
            width: 620,
            height: 400,
            backgroundColor: Projectcolors.primary,
            padding: 2,
            boxShadow: 6,
          }}
        >
          <Typography
            sx={{ align: "left", color: "#fff", fontSize: 14 }}
            variant="h4"
          >
            Projects List
          </Typography>

          <TableContainer
            component={Paper}
            style={{
              width: "100%",
              height: 286,
              padding: 0,
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 17,
            }}
          >
            <Table
              className={classes.root}
              sx={{ minWidth: 150 }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow className={classes.root}>
                  {tableHeaders.map((item, idx) => {
                    return (
                      <TableCell
                        className={classes.root}
                        sx={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          width: 50,
                          color: Projectcolors.primary,
                        }}
                      >
                        {item}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {projectDetails &&
                  projectDetails.length &&
                  projectDetails.map((item, i) => {
                    const newDate = item.time;
                    const dateStr = new Date(item.time)
                      .toDateString()
                      .substr(4); // 'Fri Apr 10 2020'

                    const projectName = item.projectName;
                    const projectID = item.projectID;

                    return (
                      <StyledTableRow sx={{ width: 100 }}>
                        <StyledTableCell
                          className={classes.tableCell}
                          sx={{
                            fontSize: 11,
                            cursor: "pointer",
                            padding: "0 16px",
                          }}
                          padding="checkbox"
                          onClick={() => handleNavigation(item.projectID,item.projectName)}
                        >
                          {item.projectName}
                        </StyledTableCell>

                        <StyledTableCell sx={{ fontSize: 11 }}>
                          {dateStr}
                        </StyledTableCell>

                        <StyledTableCell sx={{ fontSize: 5 }}>
                          <EditIcon
                            sx={{ fontSize: 11 }}
                            onClick={()=>toggleEditModal(projectName, projectID)}
                            //onClick={() => handleNavigation(item.projectID,item.projectName)}
                          />
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontSize: 5 }}>
                          <ContentCopyIcon
                            sx={{ fontSize: 11 }}
                            onClick={() => copyProject(item.projectID)}
                          />
                        </StyledTableCell>

                        <StyledTableCell sx={{ fontSize: 5 }}>
                          <DeleteIcon
                            sx={{ fontSize: 11, cursor: "pointer" }}
                            onClick={() => deleteProject(item.projectID)}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() =>
                            toggleShareModal(projectName, projectID)
                          }
                          sx={{ fontSize: 5, cursor: "pointer" }}
                        >
                          <ShareIcon sx={{ fontSize: 11 }} />
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            mt={3}
            onClick={toggleModal}
            sx={{
              // width: 170,
              // height: 50,
              fontSize: 10.5,
              marginTop: 2,
              backgroundColor: "#fff",
              fontStyle: "bold",
              color: "black",
            }}
            // color="black"
            variant="contained"
            size="large"
          >
            Create Project
          </Button>
        </Box>

        <Modal isModal={isModal} toggleModal={toggleModal} />
        <EditModal isModal={iseditModal}
        setIseditModal={setIseditModal} 
        handleeditClose={handleeditClose}
        toggleModal={toggleEditModal} 
        handleEditProject={handleEditProject} 
        editProjectName={editProjectName} 
        editClientName={editClientName} 
        editObjective={editObjective} 
        setEditClientName={setEditClientName} 
        setEditProjectName={setEditProjectName} 
        setEditObjective={setEditObjective}  
        setEditProjectID={setEditProjectID} 
        editProjectID={editProjectID}/>

        <ShareProjectModal
          isModal={shareModal}
          handleShareProject={handleShareProject}
          setEmail={setEmail}
          email={email}
          toggleModal={toggleShareModal}
        />



        {getLoader ? <GlobalLoader /> : null}
      </BgContainer2>
    </div>
  );
}

export default NewProject;
