import React from "react";
import SensitivityCase from "../SensitivyCase";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MultiCase from "../MultiCase";
import FirstPrinciples from "../FirstPrinciples";
import SelexOnline from "../SelexOnline";
import { baseURL } from "../../Config/config";

export default function AllDeploy(props) {
  const [value, setValue] = React.useState("2");
  const [timeseries, setTimeseries] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    getTimeseries();
  }, []);

  const getTimeseries = () => {
    const projectID = localStorage.getItem("projectID");
    fetch(`${baseURL}/getTimeseries?projectID=${projectID}`)
      .then((res) => res.json())
      .then((data) => {
        setTimeseries(data.timeseries);
        console.log(data.timeseries);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1", marginTop: 9 }}>
        <TabContext value={value}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {timeseries ? (
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Predicting"
                  value="2"
                  sx={{
                    fontSize: 16,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                />
                <Tab
                  label="Forecasting"
                  value="3"
                  sx={{
                    fontSize: 16,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                />
              </TabList>
            ) : (
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                 <Tab
                  label="First Principles"
                  value="1"
                  sx={{
                    fontSize: 16,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                />
                <Tab
                  label="Sensitivity Case"
                  value="2"
                  sx={{
                    fontSize: 16,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                />
                <Tab
                  label="Multi Case"
                  value="3"
                  sx={{
                    fontSize: 16,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                />
                <Tab
                  label="SelexOnline"
                  value="4"
                  sx={{
                    fontSize: 16,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                />
              </TabList>
            )}
          </Box>
          <TabPanel value="1">
            <FirstPrinciples
              editAccess={props.editAccess}
              loaded={props.loaded}
              ProjectID={props.ProjectID}
            />
          </TabPanel>

          <TabPanel value="2">
            <SensitivityCase
              editAccess={props.editAccess}
              loaded={props.loaded}
              ProjectID={props.ProjectID}
            />
          </TabPanel>
          <TabPanel value="3">
            <MultiCase
              editAccess={props.editAccess}
              loaded={props.loaded}
              ProjectID={props.ProjectID}
            />
            </TabPanel>
            <TabPanel value="4">
            <SelexOnline
              editAccess={props.editAccess}
              loaded={props.loaded}
              ProjectID={props.ProjectID}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
