import React, { useEffect, useState } from 'react';
import { baseURL } from "../Config/config";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import Projectcolors from "../Utils/Colors";

import GlobalLoader from "./CommonC/GlobalLoader";





const TCP = () => {
    const [data, setData] = useState({"independent": [], "value": [],"dependent": []});
    const [isLoading, setIsLoading] = React.useState(false);
    const [errormessage, seterrormessage] = React.useState('Something went wrong');
      const [errmessage, seterrmessage] = React.useState(false);
    
      
    
    
  
      const ProjectID = localStorage.getItem("projectID");
      console.log(ProjectID)
      

    useEffect(() => {
        const fetchData = () => {
            fetch(`${baseURL}/streamdata?projectID=${ProjectID}`)
                .then((response) => response.json())
                .then((data) => {
                    
                    if (data.error == 1) {
                        setData({"independent": [], "value": [],"dependent": []});
                    } else {
                        console.log("data updated",data);
                        setData(data);
                    }
                }) // Closing the .then block properly here
                .catch((error) => console.error('Error fetching data:', error));
        };

        const interval = setInterval(fetchData, 1000); // Fetch every second

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    function downloadcsv() {
        const projectID = localStorage.getItem("projectID");
        const projectName = localStorage.getItem("projectName");
        
    
        setIsLoading(true);
        fetch(`${baseURL}/downloadtcpcsv?projectID=${projectID}`)
          .then((res) => res.blob())
          .then((blob) => {
            var file = new Blob([blob], {
              type: "text/csv", // Set MIME type for CSV
            });
            var url = URL.createObjectURL(file);
            var link = document.createElement("a");
            link.download = projectName + 'tcp' + ".csv"; // Use .csv extension
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            fetch(`${baseURL}/deletetcpcsv?projectID=${projectID}`, {
              method: "POST",
              body: JSON.stringify(projectID),
              headers: {
                "Content-Type": "application/json",
              },
            })
              .then((response) => response.json())
              .then((result) => {
                setIsLoading(false);
              })
              .catch((error) => {
                console.error("Error:", error);
                setIsLoading(false);
              });
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            seterrormessage("Can't Download File")
            seterrmessage(true);
            console.log("Error", err);
          });
    
        
         
    
      }
    

    return (
        <div>
            <h1>Real-Time Data</h1>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography sx={{ fontSize: 14 }}>
                Download csv
              </Typography>
              <DownloadIcon
                sx={{
                  fontSize: "medium",
                  marginTop: 0.3,
                  marginRight: 2,
                  marginLeft:1,
                  color: Projectcolors.primary,
                }}
                onClick={() => downloadcsv()}
              />
              </Box>
            {data.independent.length>0 ? (
                <div>
                    <p>TimeStamp : {data.value[0]}</p>
                    {data.independent.map((value,index)=>(
                         <p>{value}: {data.value[index+1]}</p>
                    ))
                }
                {data.dependent.map((value,index)=>(
                         <p>{value}: {data.value[(data.independent.length+1)+index]}</p>
                    ))
                }
                   
                    
                </div>
            ) : (
                <p>Waiting for Connection...</p>
            )}

{isLoading ? <GlobalLoader /> : null}
        </div>
    );
};

export default TCP;

/**
import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { baseURL } from "../Config/config";


const TCP = () => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        //const socket = io('https://api.selexmb.com'); // Replace with your Flask server's address
        //const socket = io('http://127.0.0.1:5000');
        const socket = io('http://172.31.87.201:5000');

        socket.on('connect', () => {
            console.log('Connected to WebSocket');
        });

        socket.on('message', (data) => {
            setMessages((prevMessages) => [...prevMessages, data.data]);
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from WebSocket');
        });

        return () => socket.disconnect();
    }, []);

    return (
        <div>
            <h1>TCP Messages</h1>
            <ul>
                {messages.map((msg, index) => (
                    <li key={index}>{msg}</li>
                ))}
            </ul>
        </div>
    );
};
 
export default TCP;
 */

/**
 * 
 * THis code is working socket2.py file
 * from flask import Flask
from flask_socketio import SocketIO, emit
import socket
import threading

app = Flask(__name__)
app.config['SECRET_KEY'] = 'secret!'
socketio = SocketIO(app, cors_allowed_origins="*")

HOST = '127.0.0.1'  # TCP server host
PORT = 5002         # TCP server port
allmessages = []

def tcp_server():
    with socket.socket(socket.AF_INET, socket.SOCK_STREAM) as s:
        s.bind((HOST, PORT))
        s.listen()
        print(f"TCP Server listening on {HOST}:{PORT}")
        while True:
            conn, addr = s.accept()
            with conn:
                print('Connected by', addr)
                while True:
                    data = conn.recv(1024)
                    if not data:
                        print("Connection closed by client")
                        break
                    string_data = data.decode('utf-8')
                    allmessages.append(string_data)
                    print(f"Received data: {string_data}")
                    
                    # Broadcast to WebSocket clients
                    socketio.emit('message', {'data': string_data})

# Run TCP server in a separate thread
threading.Thread(target=tcp_server, daemon=True).start()

@app.route('/')
def index():
    return "TCP to WebSocket bridge is running!"

if __name__ == '__main__':
    socketio.run(app, host='0.0.0.0', port=5000)

 * 
 * 
 * 
 */
