import React from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { baseURL } from "../../Config/config";

const EditModal = (props) => {
  const navigate = useNavigate();

  const [projectName, setProjectName] = React.useState(localStorage.getItem("editprojectName"));
  const [clientname, setClientName] = React.useState(localStorage.getItem("editclientname"));
  const [objective, setObjective] = React.useState(localStorage.getItem("editobjective"));
  const [isLoading, setIsLoading] = React.useState(false);
  const [getLoader, setGetLoader] = React.useState(false);
  const [err, seterr] = React.useState("");
  // const createNewProject = async () => {
  //   navigate("/newproject");
  // }
  
   
  console.log("props",props)


  return (
    <div style={{ backgroundColor: "red" }}>
      <Modal
        open={(props.isModal)}
        onClose={props.handleeditClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          style={{
            backgroundColor: "#fff",
            height: "300px",
            width: "330px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            flex: 1,
          }}
        >

          {" "}
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
              paddingTop: "30px",
            }}
          >

            {/* <input
              onChange={(e) => setProjectName(e.target.value)}
              style={{
                width: "80%",
                height: "1%",
                marginTop: "20px",
                padding: "20px 10px",
              }}
              inputProps={{ style: { fontSize: 12,height:8 } }}
              placeholder="Projects name"
            /> */}
            <Typography style={{fontSize: "12px" }}>Enter Project Name  </Typography>

            <TextField
            //   required
            id="outlined-basic"
            variant="outlined"
            defaultValue={props.editProjectName}
            onChange={(e) => props.setEditProjectName(e.target.value)}
            sx={{ width: 300, fontSize: "30px",width:"80%"}}
            InputLabelProps={{ style: { fontSize: 12 } }}
            inputProps={{ style: { fontSize: 12,height:"4px" } }}
            // InputLabelProps={{ shrink: true }}
          />
          <Typography style={{fontSize: "12px",marginTop:"12px" }}>Client Name (Optional) </Typography>
          <TextField
            //   required
            id="outlined-basic"
            variant="outlined"
            defaultValue={props.editClientName}
            onChange={(e) => props.setEditClientName(e.target.value)}
            sx={{ width: 300, fontSize: "30px",width:"80%"}}
            InputLabelProps={{ style: { fontSize: 12 } }}
            inputProps={{ style: { fontSize: 12,height:"4px" } }}
            // InputLabelProps={{ shrink: true }}
          />
          <Typography style={{fontSize: "12px",marginTop:"12px" }}>Objective (Optional) </Typography>
          <TextField
            //   required
            id="outlined-basic"
            variant="outlined"
            defaultValue={props.editObjective}
            onChange={(e) => props.setEditObjective(e.target.value)}
            sx={{ width: 300, fontSize: "30px",width:"80%"}}
            InputLabelProps={{ style: { fontSize: 12 } }}
            inputProps={{ style: { fontSize: 12,height:"4px" } }}
            // InputLabelProps={{ shrink: true }}
          />

            {isLoading ? (
              <Box
                sx={{
                  position: "absolute",
                  right: "35px",
                  bottom: "30px",
                  height: "30px",
                  fontSize:"10px"
                }}
              >
                <CircularProgress  size={28}/>
              </Box>
            ) : (
              <Button
                onClick={() => props.handleEditProject()}
                sx={{
                  position: "absolute",
                  right: "35px",
                  bottom: "30px",
                  height: "30px",
                  fontSize:"10px"
                }}
                variant="contained"
              >
                Continue
              </Button>
            )}
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default EditModal;
