import { Box, Button, Modal, Paper, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import optimizelogo from "../../images/SelexSuite copy.png";
import Projectcolors from "../../Utils/Colors";
import Selexlogo from "../../images/SelexMB Final.png";
import Selexrisk from "../../images/SelexRiskFinal.png";

import selexmathimg from "../../images/SelexMath-2.png";



const ContactModal = ({ open, handleClose }) => {
  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 470,
    bgcolor: "white",
    border: "2px solid #000",
    p: 4,
    display: "block",
  };

  return (
    <div
      style={{
        height: window.screen.height,
      }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles} component={Paper} elevation={10}>
          <div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "white",
                justifyContent: "space-between",
              }}
            >
              <Box
                component="div"
                sx={{ display: "flex", marginLeft: "1%", marginTop: 0 }}
              >
                <img
                  style={{ height: "80px", width: "210px", marginTop: "0px" }}
                  src={optimizelogo}
                  alt="selexsuitelogo"
                />
              </Box>

              <Button
                onClick={handleClose}
                sx={{
                  display: "inline-flex",
                  color: "black",
                  fontSize: "16px",
                }}
              >
                <CloseIcon sx={{ fontSize: "20px" }} />
              </Button>
            </Box>
          </div>
          <Box sx={{ padding: 2 }}>
            <Typography sx={{ fontSize: 18 }}>
              {" "}
              SelexSuite is a unified platform combing an advanced engineering, AI-driven insights, and robust risk management to support operation. SelexMB, SelexMath and SelexRISK are designed to integrate with Aspen HYSYS to support your workflow. If you are a HYSYS User, and interested to advance your simulation, contact us via
                <Typography sx={{ marginTop: 1 ,fontSize: 18,color:Projectcolors.primary}}>emad.elgebesy@gmail.com</Typography>
            </Typography>
            <br />
            <img
                               style={{ height: "80px", width: "100px", marginTop: "0px" }}
                               src={Selexlogo}
                             />
                              <img
                               style={{ height: "80px", width: "140px", marginTop: "0px" }}
                               src={selexmathimg}
                             />
                              <img
                               style={{ height: "110px", width: "140px", marginTop: "0px" }}
                               src={Selexrisk}
                             />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ContactModal;
