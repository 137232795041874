import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Projectcolors from "../Utils/Colors";
import { baseURL } from "../Config/config";
import { Typography, Link } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import model from "../images/model.png";
import Arrow from "../images/arrow.png";
import Excel from "../images/excel.jpeg";
import { makeStyles } from "@mui/styles";
import GlobalLoader from "./CommonC/GlobalLoader";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";
import axios from "axios";
import Plot from "react-plotly.js";
import { Checkbox, FormControlLabel } from '@mui/material';


function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ fontSize: 14 }}
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  button: {
    textTransform: "none",
  },
});

function SelexOnline(props) {
  const [data, setData] = useState({
    independent: [],
    value: [],
    dependent: [],
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [errormessage, seterrormessage] = React.useState(
    "Something went wrong"
  );
  const [errmessage, seterrmessage] = React.useState(false);
  const [timestamps, setTimestamps] = useState([
   
  ]);
  const [getLoader, setGetLoader] = React.useState(false);
  //const [yAxisData, setYAxisData] = useState({}); // Stores nested Y-axis values
  const [yAxisData, setYAxisData] = useState({});
  const [labels, setLabels] = useState([]);
  const [visibleAxes, setVisibleAxes] = useState([]);
  const [startTimestamp, setStartTimestamp] = useState(timestamps[0]);
  const [saved, setSaved] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [endTimestamp, setEndTimestamp] = useState(
    timestamps[timestamps.length - 1]
  );
  const [connectionClose, setConnectionClose] = useState(true);
  React.useEffect(() => {
      getselexonlineData();
    }, []);

     const getselexonlineData = () => {
        setGetLoader(true);
        const projectID = localStorage.getItem("projectID");
        fetch(
          `${baseURL}/getselexonlinedata?projectID=${projectID}`
        )
          .then((res) => res.json())
          .then((data) => {
            setSaved(false);
            setRefresh(false);
            setData(data.newdata)
            setConnectionClose(data.isClose)
            const joinedArray = data.newdata.independent.concat(data.newdata.dependent);
            setLabels(joinedArray);
            setVisibleAxes(Array(joinedArray.length).fill(true));
            if (Object.keys(data.yAxisData).length === 0){
                const newData = {}
                joinedArray.forEach((key) => {
                    newData[key] = [];
                  });
                  setYAxisData(newData);
                  
            }
            else{
                setYAxisData(data.yAxisData);
                setTimestamps(data.timestamps);
                setStartTimestamp(data.timestamps[0])
                setEndTimestamp(data.timestamps[(data.timestamps.length-1)])
            }
    
    
              setGetLoader(false);

          })
          .catch((err) => {
            setGetLoader(false);
            console.log(err);
          });
      };
      
  
  useEffect(() => {
    let interval;
    const fetchData = () => {
      fetch(`${baseURL}/streamdata?projectID=${ProjectID}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.error == 1) {
            setData({ independent: [], value: [], dependent: [] });
            setSaved(false);
            setRefresh(false);
          } else {
            setSaved(false);
            setRefresh(false);
            setData(data)
            setConnectionClose(data.isClose);
            // Update timestamps
            setTimestamps((prev) => 
                {
                    const newTimestamps = [...prev, data.value[0]];
                    return newTimestamps; // Return the updated array

                });
            setStartTimestamp(timestamps[0]);
            setEndTimestamp(data.value[0])
            
  
            // Update yAxisData
            setYAxisData((prevData) => {
              const newData = { ...prevData }; // Shallow copy of the previous state
  
              // Initialize if empty
              if (Object.keys(newData).length === 0) {
                data.independent.forEach((key) => {
                  newData[key] = [];
                });
                data.dependent.forEach((key) => {
                  newData[key] = [];
                });
              }
  
              // Append new values
              data.independent.forEach((key, index) => {
                newData[key] = [...(newData[key] || []), data.value[index + 1]]; // Ensure immutability
              });
              data.dependent.forEach((key, index) => {
                newData[key] = [
                  ...(newData[key] || []),
                  data.value[data.independent.length + 1 + index],
                ];
              });
              

              return newData; // Return updated object
            });

  
            // Update labels and visibility
            const joinedArray = data.independent.concat(data.dependent);
            setLabels(joinedArray);
            setVisibleAxes(Array(joinedArray.length).fill(true));
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    };
    
    if(connectionClose == false){
    interval = setInterval(fetchData, 1000);
    } // Fetch every second

  
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [connectionClose]);
     

  const handleStartChange = (e) => {
    setStartTimestamp(e.target.value);
  };

  const handleEndChange = (e) => {
    setEndTimestamp(e.target.value);
  };

  const handleSave = () => {
    try {
      const projectID = localStorage.getItem("projectID");
      
       const body = JSON.stringify({
        projectID,
        data,
        timestamps,
        yAxisData,
      });
      const config = {
       
        headers: {
          "Content-Type": "application/json",
        },
      };
    
      setGetLoader(true);
      
      axios
        .post(`${baseURL}/saveselexonlinedata`, body,config)
       
        .then((result) => {
          if (result.data.message == 1){
            setSaved(true);
            setGetLoader(false);
            
          }
          else{
            setGetLoader(false);
          }
        })
        .catch((error) => {
          setGetLoader(false);
          console.error("Error:", error);
        });
    } catch (error) {
      setGetLoader(false);
      console.log("error", error);
    }
  };


  const handleRefresh = () => {
    try {
      const projectID = localStorage.getItem("projectID");
      
       const body = JSON.stringify({
        projectID,
        
      });
      const config = {
       
        headers: {
          "Content-Type": "application/json",
        },
      };
    
      setGetLoader(true);
      
      axios
        .post(`${baseURL}/refreshselexonlinedata`, body,config)
       
        .then((result) => {
          if (result.data.message == 1){
            setRefresh(true);
            setSaved(false);

            
            setData({independent:result.data.dataa.independent,value: [],dependent:result.data.dataa.dependent});
            const joinedArray = result.data.dataa.independent.concat(result.data.dataa.dependent);
            setLabels(joinedArray);
            setVisibleAxes(Array(joinedArray.length).fill(true));
            if (Object.keys(result.data.yAxisData).length === 0){
              const newData = {}
              joinedArray.forEach((key) => {
                  newData[key] = [];
                });
                setYAxisData(newData);
                
                
          }
          setTimestamps(result.data.timestamps);
          setStartTimestamp("");
          setEndTimestamp("");
          setGetLoader(false);





            
            
          }
          else{
            setGetLoader(false);
          }
        })
        .catch((error) => {
          setGetLoader(false);
          console.error("Error:", error);
        });
    } catch (error) {
      setGetLoader(false);
      console.log("error", error);
    }
  };

  // Filter timestamps and corresponding data based on selected range
  const filteredTimestamps = timestamps.filter(
    (t) => t >= startTimestamp && t <= endTimestamp
  );

  const handleCheckboxChange = (key, i) => {
    let copyaxes = [...visibleAxes];
    copyaxes[i] = !copyaxes[i];
    setVisibleAxes(copyaxes);
    
  };
  const yAxisKeys = Object.keys(yAxisData);
  let filteredData;
  filteredData = yAxisKeys
    .filter((_, index) => visibleAxes[index]) // Include only visible axes
    .map((key, index) => ({
      x: filteredTimestamps.length>0?filteredTimestamps:timestamps,
      y: yAxisData[key],
      type: "scatter",
      mode: "lines+markers",
      name: key, // Use the key (e.g., reflux, naptha, etc.) as the label
      yaxis: index === 0 ? "y" : `y${index + 1}`, // First dataset uses `y`, others use `y2`, `y3`, etc.
    }));
  const ProjectID = localStorage.getItem("projectID");

  
  function downloadcsv() {
    const projectID = localStorage.getItem("projectID");
    const projectName = localStorage.getItem("projectName");

    setIsLoading(true);
    fetch(`${baseURL}/downloadtcpcsv?projectID=${projectID}`)
      .then((res) => res.json())
      .then((data) => {
        /**
        var file = new Blob([blob], {
          type: "text/csv", // Set MIME type for CSV
        });
        var url = URL.createObjectURL(file);
         */
        setGetLoader(true);
        var url = data.url;
        var link = document.createElement("a");
        link.download = projectName + "tcp" + ".csv"; // Use .csv extension
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetch(`${baseURL}/deletetcpcsv?projectID=${projectID}`, {
          method: "POST",
          body: JSON.stringify(projectID),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setGetLoader(false);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error:", error);
            setGetLoader(false);
            setIsLoading(false);
          });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setGetLoader(false);
        seterrormessage("Can't Download File");
        seterrmessage(true);
        console.log("Error", err);
      });
  }

  return (
    <div style={{ marginTop: "1px" }}>
      <Box sx={{ border: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            border: 0,
            marginTop: 1,
          }}
        >
          <Box
            component={Paper}
            elevation={10}
            //m="auto"
            sx={{
              height: 615,
              width: 1790,
              padding: 2,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                border: 0,
                width: 1020,
                height: 480,
              }}
            >
              <div
                style={{
                  //display: "flex",
                  //flexDirection: "row",
                  //alignItems: "center",
                  float: "right",
                  ml: 200,
                  cursor: "pointer",
                  marginTop: 2,
                }}
              >
                <Link
                  sx={{
                    fontSize: "13px",
                    fontStyle: "bold",
                    float: "right",
                    ml: 0,
                    mr: 2,
                    // marginTop: 0,
                    // marginBottom: 1,
                    textTransform: "none",
                  }}
                  variant="contained"
                  size="large"
                  textTransform="none"
                >
                  <img
                    alt="arrow"
                    src={Excel}
                    style={{
                      marginTop: 0,
                      marginBottom: 1,
                      marginRight: 1,
                      width: 30,
                      height: 30,
                    }}
                  />
                  <span
                    style={{ marginLeft: 4, marginRight: 10 }}
                    onClick={() => downloadcsv()}
                  >
                    Download data
                  </span>
                </Link>
              </div>

              <Box
                m="auto"
                sx={{
                  width: 1050,
                  // height: 390,
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "35px",

                  //marginLeft: 2,
                }}
              >
                {/* LEFT DIV */}
                <Box
                  sx={{
                    width: 230,
                    height: 310,
                    marginTop: "0px",
                    marginBottom: "30px",
                    mr: 2,
                    // marginRight: "10px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      position: "relative",
                      top: 8,
                      left: 15,
                      width: 155,
                    }}
                  >
                    <Typography sx={{ fontSize: "13PX", fontWeight: "bold" }}>
                      &nbsp;&nbsp;Independent Variables
                    </Typography>
                  </div>
                  <Box
                    sx={{
                      width: 230,
                      height: 310,
                      marginTop: "0px",
                      border: 1,
                      borderColor: Projectcolors.primary,
                      padding: 1,
                      overflowY: "scroll",
                      marginLeft: "1px",
                    }}
                  >
                    <div>
                      {data.independent.length > 0 ? (
                        <div>
                          <FormControl
                            sx={{
                              float: "left",
                              marginRight: 2,
                              marginTop: 2,
                              marginLeft: 2,
                              width: 160,
                            }}
                            size="small"
                          >
                            <Typography sx={{ fontSize: 11 }}>
                              TimeStamp
                            </Typography>
                            <TextField
                              id="datetime-local"
                              placeholder=""
                              //type="datetime-local"
                              defaultValue={data.value.length > 0 ?data.value[0]:""}
                              value={data.value.length > 0 ?data.value[0]:""}
                              sx={{ width: "170px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                fontSize: "11px",
                                height: "37px",
                              }}
                            />
                            <img
                              alt="arrow"
                              src={Arrow}
                              style={{
                                width: "160px",
                                height: "28px",
                                marginTop: 1,
                                // marginRight:5
                                // backgroundColor:"red"
                              }}
                            />
                          </FormControl>
                          {data.independent.map((value, index) => (
                            <FormControl
                              sx={{
                                float: "left",
                                marginRight: 2,
                                marginTop: 2,
                                marginLeft: 2,
                                width: 160,
                              }}
                              size="small"
                            >
                              <Typography sx={{ fontSize: 11 }}>
                                {value}
                              </Typography>
                              <TextField
                                id="datetime-local"
                                placeholder=""
                                //type="datetime-local"
                                defaultValue={data.value.length > 0 ?data.value[index + 1]:""}
                                value={data.value.length > 0 ?data.value[index + 1]:""}
                                sx={{ width: "170px" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  fontSize: "11px",
                                  height: "37px",
                                }}
                              />
                              <img
                                alt="arrow"
                                src={Arrow}
                                style={{
                                  width: "160px",
                                  height: "28px",
                                  marginTop: 1,
                                  // marginRight:5
                                  // backgroundColor:"red"
                                }}
                              />
                            </FormControl>
                          ))}
                        </div>
                      ) : (
                        <p>Waiting for Connection...</p>
                      )}
                    </div>
                  </Box>
                </Box>
                {/* CENTER DIV */}
                <Box sx={{ height: 390 }}>
                  <Box
                    sx={{
                      width: 355,
                      height: 310,
                      padding: 5,
                      boxShadow: 9,
                      border: 1,
                      borderWidth: "4px",
                      borderColor: Projectcolors.primary,
                      borderRadius: 3,
                      fontSize: 20,
                      textAlign: "center",
                      marginTop: 3,
                      marginRight: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: "13px" }}>
                      SelexMB Trained Model
                    </Typography>

                    <img
                      alt="model"
                      src={model}
                      style={{
                        marginTop: 40,
                        objectFit: "contain",
                        height: "130px",
                      }}
                    />
                  </Box>
                  {connectionClose?(
                    <Button
                    editAccess={props.editAccess}
                    disabled={!props.editAccess}
                    onClick={() => setConnectionClose(false)}
                    mr={4}
                    sx={{
                      width: 140,
                      height: 35,
                      fontSize: "12px",
                      backgroundColor: Projectcolors.primary,
                      color: "#fff",
                      fontStyle: "bold",
                      marginBottom: 2,
                      float: "left",
                      marginRight: 5,
                      marginTop: 2,
                      marginLeft: 2,
                      textTransform: "none",
                    }}
                    variant="contained"
                    size="large"
                    textTransform="none"
                  >
                    Start
                  </Button>
                  ):(
                    <Button
                            editAccess={props.editAccess}
                            disabled={!props.editAccess}
                            onClick={() => setConnectionClose(true)}
                            mr={4}
                            sx={{
                              width: 140,
                              height: 35,
                              fontSize: "12px",
                              backgroundColor: Projectcolors.primary,
                              color: "#fff",
                              fontStyle: "bold",
                              marginBottom: 2,
                              float: "left",
                              marginRight: 5,
                              marginTop: 2,
                              marginLeft: 2,
                              textTransform: "none",
                            }}
                            variant="contained"
                            size="large"
                            textTransform="none"
                          >
                            Stop
                          </Button>
                  )}
                  
                          {connectionClose == false?(
                             <Typography
                             sx={{
                               width: 140,
                               height: 35,
                               fontSize: "14px",
                               color: "#000",
                               fontStyle: "bold",
                               marginBottom: 2,
                               float: "left",
                               marginRight: 5,
                               marginTop: 2,
                               marginLeft: 0,
                               textTransform: "none",
                               display:"inline-flex"
                             }}>
                               <Box
         sx={{
           width: 12, // Set the size of the dot
           height: 12,
           marginRight:1,
           marginTop:0.5,
           borderRadius: "50%", // Make it circular
           backgroundColor: "green", // Green color
           animation: "blink 1s infinite", // Blinking animation
           "@keyframes blink": {
             "0%": { opacity: 1 },
             "50%": { opacity: 0.3 },
             "100%": { opacity: 1 },
           },
         }}
       />
                               Listening</Typography>
                          ):(
null
                          )}
                         
                         <Box>
                         <Button
                    editAccess={props.editAccess}
                    disabled={!props.editAccess}
                    onClick={() => handleSave()}
                    mr={4}
                    sx={{
                      width: 140,
                      height: 35,
                      fontSize: "12px",
                      backgroundColor: Projectcolors.primary,
                      color: "#fff",
                      fontStyle: "bold",
                      marginBottom: 2,
                      float: "left",
                      marginRight: 5,
                      marginTop: 1,
                      marginLeft: 2,
                      textTransform: "none",
                    }}
                    variant="contained"
                    size="large"
                    textTransform="none"
                  >
                    Save
                  </Button>   
                  {saved?(
                             <Typography
                             sx={{
                               width: 140,
                               height: 35,
                               fontSize: "14px",
                               color: "green",
                               fontStyle: "bold",
                               marginBottom: 2,
                               float: "left",
                               marginRight: 5,
                               marginTop: 1,
                               marginLeft: 0,
                               border:1,
                               borderRadius:2,
                               padding:1
                             }}>
                              
                               Saved Sucessfully</Typography>
                          ):(
                            null
               )}
                  </Box>  
                  <Box>
                         <Button
                    editAccess={props.editAccess}
                    disabled={!props.editAccess}
                    onClick={() => handleRefresh()}
                    mr={4}
                    sx={{
                      width: 140,
                      height: 35,
                      fontSize: "12px",
                      backgroundColor: Projectcolors.primary,
                      color: "#fff",
                      fontStyle: "bold",
                      marginBottom: 2,
                      float: "left",
                      marginRight: 5,
                      marginTop: 1,
                      marginLeft: 2,
                      textTransform: "none",
                    }}
                    variant="contained"
                    size="large"
                    textTransform="none"
                  >
                    Restart
                  </Button>   
                  {refresh?(
                             <Typography
                             sx={{
                               width: 140,
                               height: 35,
                               fontSize: "14px",
                               color: "green",
                               fontStyle: "bold",
                               marginBottom: 2,
                               float: "left",
                               marginRight: 5,
                               marginTop: 1,
                               marginLeft: 0,
                               border:1,
                               borderRadius:2,
                               padding:1
                             }}>
                              
                               Restart Sucessful</Typography>
                          ):(
                            null
               )}
                  </Box>  
                </Box>
                {/* RIGHT DIV */}
                <Box
                  sx={{
                    width: "365px",
                    height: 310,
                    marginTop: "0px",
                    marginLeft: "10px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      position: "relative",
                      top: 8,
                      left: 15,
                      width: 143,
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      &nbsp;&nbsp;Dependent Variables
                    </Typography>
                  </div>
                  <Box
                    sx={{
                      width: "376px",
                      height: 310,
                      marginTop: "2px",
                      border: 1,
                      borderColor: Projectcolors.primary,
                      padding: 1,
                      overflowY: "scroll",
                      marginLeft: "1px",
                    }}
                  >
                    {data.dependent.map((value, index) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 1,
                          }}
                        >
                          <Box sx={{ width: 260 }}>
                            <Typography
                              sx={{ fontSize: "11px", marginLeft: 1 }}
                            >
                              {value}
                            </Typography>
                            <Typography>
                              <img
                                alt="arrow"
                                src={Arrow}
                                style={{
                                  width: "160px",
                                  height: "28px",
                                  marginTop: 0,
                                  marginLeft: "8px",
                                  // marginRight:5
                                  // backgroundColor:"red"
                                }}
                              />
                            </Typography>
                          </Box>
                          <Box>
                            <FormControl
                              sx={{
                                float: "left",
                                marginTop: 0,
                                marginLeft: 0,
                                marginRight: 3,
                              }}
                              variant="outlined"
                              size="small"
                            >
                              <OutlinedInput
                                sx={{
                                  width: "135px",
                                  fontSize: "11px",
                                  height: "30px",
                                  marginTop: 2,
                                }}
                                id="outlined-basic"
                                variant="outlined"
                                value={data.value.length > 0 ?
                                  data.value[
                                    data.independent.length + 1 + index
                                  ]:""
                                }
                                placeholder=" "
                                size="small"
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ border: 0, marginTop: 0, mr: 2, width: 700, padding: 2  }}
              component={Paper}
              elevation={10}
            >
              <Typography sx={{ fontSize: "13PX", fontWeight: "bold" }}>
                Graph Controls
              </Typography>

              <Box sx={{ display: "flex", flexDirection: "row", border: 0 }}>
                <Box sx={{ display: "flex", flexDirection: "column",width:"450px",p:1 ,border: "1px solid #ccc",mt:1}}>
                    <Box sx={{ display: "inline-flex", alignItems: "center"}}>
                    <Typography sx={{ fontSize: "12PX" }}>Start Timestamp:</Typography>
                    <TextField
          type="datetime-local"
          value={startTimestamp}
          onChange={handleStartChange}
          InputLabelProps={{
            shrink: true,  // Ensures the label remains above the input field
          }}
          inputProps={{
            min: timestamps[0],
            max: timestamps[timestamps.length - 1],
          }}
          
          variant="outlined"
          margin="normal"
          sx={{ml:2}}
        />

                    </Box>
                    <Box sx={{display: "inline-flex", alignItems: "center"}}>
                    <Typography sx={{ fontSize: "12PX", }}>End Timestamp:</Typography>
                    <TextField
          type="datetime-local"
          value={endTimestamp}
          onChange={handleEndChange}
          InputLabelProps={{
            shrink: true,  // Ensures the label remains above the input field
          }}
          inputProps={{
            min: timestamps[0],
            max: timestamps[timestamps.length - 1],
          }}
          
          variant="outlined"
          margin="normal"
          sx={{ml:2}}
        />
                    </Box>
                
                </Box>
                <Box
                  sx={{
                    width: "450px",
                    height: "160px", // Set the maximum height for the box
                    overflowY: "auto", // Enable vertical scrolling
                    border: "1px solid #ccc", // Optional border around the box
                    padding: "8px", // Optional padding around the content
                    ml:2,
                    mt:1
                  }}
                 
                  
                >
                    <Typography sx={{ fontSize: "12PX" }} >Select Y Axes</Typography>
                  {Object.keys(yAxisData).map((key, i) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox
                          checked={visibleAxes[i]}
                          onChange={() => handleCheckboxChange(key, i)}
                          color="primary"
                        />
                      }
                      label={key}
                    />
                  ))}
                </Box>
              </Box>
              {Object.keys(yAxisData).length > 0 ? (
                <Plot
                  data={filteredData}
                  layout={{
                    xaxis: {
                      title: "Timestamp",
                      showgrid: true,
                      domain: [visibleAxes.filter(Boolean).length * 0.1, 1],
                      showline: true,
                      tickangle: 0,
                      tickfont: {
                        size: 10,
                      },
                      rangeslider: {
                        visible: true, // Enable the range slider
                        thickness: 0.05, // Control the thickness of the slider (optional)
                        bordercolor: "gray", // Border color of the slider (optional)
                      },
                    },
                    yaxis: {
                      showgrid: true,
                      // Optionally disable the zero line if not needed
                    },

                    // Dynamically add Y-axes to layout
                    ...yAxisKeys
                      .filter((_, index) => visibleAxes[index]) // Filter only visible axes
                      .reduce((layout, key, index, array) => {
                        const axisKey =
                          index === 0 ? "yaxis" : `yaxis${index + 1}`;
                        const totalVisibleAxes = array.length; // Total visible axes
                        const spacing = 1 / (totalVisibleAxes + 4);
                        layout[axisKey] = {
                          title: {
                            text: key, // Use the dataset name as the axis title
                            standoff: 0.2, // Adjust the distance between the axis and the title
                          },
                          anchor: "free",
                          side: "left", // Place all axes on the left
                          overlaying: "y", // Overlay axes on the first Y-axis
                          //position: 0.1 * index * 1.10,
                          position: index * spacing,
                          tickangle: -90,
                          showline: true, // Disable Y-axis gridlines
                          linecolor: "black", // Set the color of the line under the Y-axis
                          linewidth: 1, // Set the thickness of the line
                          zeroline: true,
                        };
                        return layout;
                      }, {}),
                    paper_bgcolor: "#ffffff", // Background color of the entire paper (outside the plot)
                    plot_bgcolor: "#f9f9f9", // Background color of the plot area (inside the axes)
                    bordercolor: "#000000", // Border color (black in this case)
                    borderwidth: 2, // Border width around the plot

                    legend: {
                      x: 1, // Position it at the far right
                      y: 0, // Position it at the bottom
                      xanchor: "right", // Align the right side of the legend with the x position
                      yanchor: "bottom", // Align the bottom side of the legend with the y position
                      orientation: "v", // Horizontal legend items
                    },

                    margin: {
                      l: 50, // Increase left margin for Y-axis ticks
                      r: 50, // Right margin if needed
                      t: 50, // Top margin
                      b: 50, // Bottom margin
                    },
                  }}
                  useResizeHandler={true}
                  style={{ width: "100%", height: "370px" }}
                />
              ) : (
                <p>Loading graph...</p>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {getLoader ? <GlobalLoader /> : null}
    </div>
  );
}

export default SelexOnline;
