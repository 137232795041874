import React, { useState ,useEffect} from "react";
import SelexAnalysisNavbar from "./CommonC/SelexAnalysisNavbar";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { baseURL } from "../Config/config";
import Plot from "react-plotly.js";
import DownloadIcon from "@mui/icons-material/Download";
import {  useDispatch } from "react-redux";
import { renderMatches, useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GlobalLoader from "./CommonC/GlobalLoader";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ZoomIn } from '@material-ui/icons';
import IconButton from "@mui/material/IconButton";
import { DialogContent } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";

import { AppBar, Tabs } from '@mui/material';
import { Checkbox } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchIcon from "@mui/icons-material/Search";
import { Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';
import Excel from "../images/excel.jpeg";
import selexmb from "../images/SelexMB Final.png";
import aimb from "../images/AIMB.png";


import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Table,
} from "@mui/material";

import Projectcolors from "../Utils/Colors";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";




const menuprops = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width:50
    },
  },
};


function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ fontSize: 14 }}
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function AnalysisLandingPage(props) {
  const [isModalDiscreteOpen, setDiscreteModalOpen] = useState(false);
  const [isModalStochasticOpen, setStochasticModalOpen] = useState(false);
  const [isModalCalculationsOpen, setModalCalculationsOpen] = useState(false);
  const [isModalExpUOMOpen, setModalExpUOMOpen] = useState(false);
  const [isModalExpEqns, setModalExpEqns] = useState(false);
  const [isModalExplicitOpen, setModalExplicitOpen] = useState(false);
  const [isModalExplicitOpenRange, setModalExplicitOpenRange] = useState(false);
  const [isModalExpOpenRange, setModalExpOpenRange] = useState(false);

  const [equation, setEquation] = useState([]);
  const [dependent, setDependent] = useState([]);
  const [selectedTab, setSelectedTab] = React.useState(0);

  const [uom, setUOM] = useState([' ']);
  const [inuom, setinuom] = useState(" ");
  const [remark, setRemark] = useState([' ']);
  const [inremark, setinRemark] = useState(" ");
  const [explicit, setExplicit] = useState([]);
  const [deinitial, setDeinitial] = useState([]);
  const [result, setResult] = useState("");
  const [independent, setIndependent] = useState("");
  const [xlabel, setXlabel] = useState("X axis");
  const [stoxlabel, setstoXlabel] = useState("X axis");
  const [indeinitial, setIndiinitial] = useState(0);
  const [indestep, setIndiStep] = useState(0);
  const [indefinal, setIndifinal] = useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isdiscreteLoading, setIsdiscreteLoading] = React.useState(false);
  const [tabledisplay, setTabledisplay] = React.useState([]);
  const [ydata, setYdata] = React.useState([]);
  const [stotabledisplay, setstoTabledisplay] = React.useState([]);
  const [stoydata, setstoYdata] = React.useState([]);
  const [graphdata, setGraphdata] = React.useState([]);
  const [initialgraph, setInitialGraph] = React.useState([]);
  const [graphstodata, setGraphstodata] = React.useState([]);
  const [initialstograph, setInitialstoGraph] = React.useState([]);
  const [eachequation, seteachEquation] = useState("");
  const [eachdependent, seteachDependent] = useState("");
  const [eachexplicit, seteachExplicit] = useState("");
  const [expvar, setexpvar] = useState([]);
  const [expvarrange, setexpvarrange] = useState([]);
  const [eachdeinitial, seteachDeinitial] = useState(0);
  const [eachuom, seteachUom] = useState("");
  const [eachremark, seteachRemark] = useState("");
  const [errmessage, seterrmessage] = React.useState(false);
  const [edit, setEdit] = React.useState(true);
  const [errormessage, seterrormessage] = React.useState('Something went wrong');
  const [calculate, setcal] = useState(false);
  const [eachmin, seteachmin] = useState("");
  const [eachmax, seteachmax] = useState("");
  const [fp, setfp] = React.useState([]);
  const [isdone, setDone] = React.useState(false);
  const [iscalculationsdone, setCalculationsDone] = React.useState(false);
  const [runs, setruns] = React.useState([]);
  const [totalruns, settotalruns] = React.useState(0);
  const [isstodone, setStochasticDone] = React.useState(false);
  const [stofp, setstofp] = React.useState([]);
  const [storuns, setstoruns] = React.useState(0);
  const [newcalcy, setnewcalcy] = React.useState([]);
  const [addcalcyrow, setaddcalcyrow] = React.useState([]);
  const colorslist = [
    "#3366cc",
    "#dc3912",
    "#ff9900",
    "#109618",
    "#990099",
    "#0099c6",
    "#dd4477",
    "#aaaa11",
    "#743411",
    "#e67300",
    "#22aa99",
    "#994499",
    "#22aa99",
    "#66aa00",
    "#6633cc",
    "#3366cc",
    "#8b0707",
    "#651067",
    "#329262",
    "#5574a6",
    "#3b3eac",
    "#b77322",
    "#16d620",
    "#b91383",
    "#f4359e",
    "#9c5935",
    "#a9c413",
    "#2a778d",
    "#668d1c",
    "#bea413",
    "#0c5922",
    "#b82e2e",
  ];
  const [editableindex, seteditableindex] = useState(-1);
  const [expedit, setexpedit] = useState(-1);
  const [expeditrange, setexpeditrange] = useState(-1);
  const [calculatestart, setCalculatestart] = React.useState(false);
  const [calculatediscretestart, setCalculatediscretestart] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [value, setValue] = React.useState("1");
  const [reportvalue, setReportValue] = React.useState("4");
  const [loadednew, setLoadedNew] = React.useState(false);
  const [pid, setPid] = React.useState(false);
  const [info, setinfo] = React.useState(false);
  const [limits, setlimits] = React.useState([]);
  const [limitseqn, setlimitseqn] = React.useState([]);
  const [eachlimitequation, seteachlimitEquation] = useState("");
  const [totalvar, settotalvar] = useState([]);
  const [allvalues, setallvalues] = useState({});
  const [checkedState, setCheckedState] = useState([]);
  const [stocheckedState, setstoCheckedState] = useState([]);
  const [calcycheckedState, setcalcyCheckedState] = useState([]);
  const [keys, setkeys] = useState([]);
  const [stokeys, setstokeys] = useState([]);
  const [xgraph, setX] = useState([]);
  const [ygraph, setY] = useState([]);
  const [records, setRecords] = useState([]);
  const [storecords, setstoRecords] = useState([]);
  const [alluoms, setalluoms] = useState([]);
  const [getvalues, setgetValues] = React.useState([]);
  const [addfprow, setaddfprow] = React.useState([]);
  const [addstofprow, setaddstofprow] = React.useState([]);
  const [isPopupOpen, setPopupOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState('option1');
  const [datakeys, setdatakeys] = useState([]);
  const [newuom, setnewuom] = useState([]);
  const [newdesc, setnewdesc] = useState([]);
  const [calcytabledisplaynew, setcalcytabledisplaynew] = React.useState([]);
   const [formula, setFormula] = React.useState("");
    const [formind, setFormind] = React.useState(0);










  const [pname, setPname] = React.useState(false);  
  let loaded = false;
  let ProjectID = false;
  let projectName = false;
  let editAccess = true;
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.state.loaded) {
      setLoadedNew(location.state.loaded);
    }
  }, [location.state.loaded]);
  
  useEffect(() => {
    if (location.state.ProjectID) {
      ProjectID = location.state.ProjectID;
      setPid(ProjectID)
    }
  }, [location.state.ProjectID]);
  
  useEffect(() => {
    if (location.state.projectName) {
      projectName = location.state.projectName;
      setPname(projectName)
    }
  }, [location.state.projectName]);
  
  useEffect(() => {
    if (location.state.editAccess) {
      setEdit(location.state.editAccess);
    }
  }, [location.state.editAccess]);
  
  useEffect(() => {
    if (loadednew) {
      getdiscretedata();
      getstochasticdata();
      getnewanalysisdata();
    }
  }, [loadednew]);

  const handleMagnifyClick = (formulaa,i) => {
    setFormula(formulaa)
    setFormind(i)
    setPopupOpen(true);
  };
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == "1"){
    setReportValue("4")
    }
    if (newValue == "2"){
      setReportValue("5")
      }
      if (newValue == "3"){
        setReportValue("6")
        }
  };
  const handleReportChange = (event, newValue) => {
    setReportValue(newValue);
  };
  const handleInfoClick = () => {
    setinfo(true);
  };
  const handleCloseinfo = () => {
    setinfo(false);
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  console.log("hyqiuyiwqwiy",selectedValue);


  function addCondition(){
    var limitscopy = [...limits];
    limitscopy.push([])
    setlimits(limitscopy);
    console.log(limitscopy)


  }

  //console.log("graphdata",graphdata)
  //console.log("initialGraph",initialgraph)
  const handleOnChange = (value, check,i) => {
    //console.log(value,check)
    //console.log("graphdata",graphdata)
    var randnum = Math.floor(Math.random() * (30 - 0 + 1)) + 0
    if (check=== false) {
      var checkedprev = [...checkedState]
      //console.log(checkedprev)
      checkedprev[i] = true;
      setCheckedState(checkedprev);
      console.log("xgraph",xgraph)
      console.log("graphdata",graphdata)


      const updateGraph = [
        ...graphdata,
        {
          //x: Array.from(Array(allvalues[value].length).keys()),
          x:ydata[i].length,
          y: ydata[i],
          type: "line",
          mode: "lines+markers",
          marker: { color: colorslist[randnum] },
          name: value,
         
        },
      ];
      setGraphdata(updateGraph);
      //console.log(updateGraph)
      //console.log("records",records)
      setRecords([...records,value]);

    }
    else{
      //console.log("i=",i)
      var checkedprev = [...checkedState]
      //console.log(checkedprev)
      checkedprev[i] = false; // Problem: mutates list[0]
      setCheckedState(checkedprev);
      let graphdatacopyy = [...graphdata]
      let recordscopy = [...records]
      var ind = recordscopy.indexOf(value)
      graphdatacopyy.splice(ind,1)
      setGraphdata(graphdatacopyy)
      recordscopy.splice(ind,1)
      setRecords(recordscopy)
      //console.log("records",records)


    }


  }

  const handleOnstoChange = (value, check,i) => {
    //console.log(value,check)
    //console.log("graphdata",graphdata)
    var randnum = Math.floor(Math.random() * (30 - 0 + 1)) + 0
    if (check=== false) {
      var checkedprev = [...stocheckedState]
      //console.log(checkedprev)
      checkedprev[i] = true;
      setstoCheckedState(checkedprev);
      //console.log("xgraph",xgraph)
      //console.log("graphdata",graphdata)


      const updateGraph = [
        ...graphstodata,
        {
          //x: Array.from(Array(allvalues[value].length).keys()),
          x:storuns,
          y: stoydata[i],
          type: "line",
          mode: "lines+markers",
          marker: { color: colorslist[randnum] },
          name: value,
         
        },
      ];
      setGraphstodata(updateGraph);
      console.log(updateGraph)
      console.log("records",records)
      setstoRecords([...storecords,value]);

    }
    else{
      //console.log("i=",i)
      var checkedprev = [...stocheckedState]
      //console.log(checkedprev)
      checkedprev[i] = false; // Problem: mutates list[0]
      setstoCheckedState(checkedprev);
      let graphdatacopyy = [...graphstodata]
      let recordscopy = [...storecords]
      var ind = recordscopy.indexOf(value)
      graphdatacopyy.splice(ind,1)
      setGraphstodata(graphdatacopyy)
      recordscopy.splice(ind,1)
      setstoRecords(recordscopy)
      //console.log("records",records)


    }


  }

  const handleOnCalcyChange = (value, check,i) => {
    //console.log(value,check)
    //console.log("graphdata",graphdata)
    if (check=== false) {
      var checkedprev = [...calcycheckedState]
      //console.log(checkedprev)
      checkedprev[i] = true;
      setcalcyCheckedState(checkedprev);
      //console.log("xgraph",xgraph)
      //console.log("graphdata",graphdata)
    }
    else{
      //console.log("i=",i)
      var checkedprev = [...calcycheckedState]
      //console.log(checkedprev)
      checkedprev[i] = false; // Problem: mutates list[0]
      setcalcyCheckedState(checkedprev);


    }


  }
  



//new functions for this file
  const handleDeclaInp = (event, idx, j) => {
    if (j == "variables") {
      const fpcopy = Array.from(fp);
      fpcopy[idx][0] = event.target.value;
      setfp(fpcopy);
    }
    
    if (j == "uom") {
      const fpcopy = Array.from(fp);
      fpcopy[idx][1] = event.target.value;
      setfp(fpcopy);
    }
    if (j == "desc") {
      const fpcopy = Array.from(fp);
      fpcopy[idx][2] = event.target.value;
      setfp(fpcopy);
    }
    if (j == "minimum") {
      const fpcopy = Array.from(fp);
      fpcopy[idx][3] = event.target.value;
      setfp(fpcopy);
    }
    if (j == "maximum") {
      const fpcopy = Array.from(fp);
      fpcopy[idx][4] = event.target.value;
      setfp(fpcopy);
    }
    if (j == "step") {
      const fpcopy = Array.from(fp);
      fpcopy[idx][5] = event.target.value;
      setfp(fpcopy);
    }
    
  };

const handleDeclaInpSto = (event, idx, j) => {
    if (j == "variables") {
      const stofpcopy = Array.from(stofp);
      stofpcopy[idx][0] = event.target.value;
      setstofp(stofpcopy);
    }
    
    if (j == "uom") {
      const stofpcopy = Array.from(stofp);
      stofpcopy[idx][1] = event.target.value;
      setstofp(stofpcopy);
    }
    if (j == "desc") {
      const stofpcopy = Array.from(stofp);
      stofpcopy[idx][2] = event.target.value;
      setstofp(stofpcopy);
    }
    if (j == "minimum") {
      const stofpcopy = Array.from(stofp);
      stofpcopy[idx][3] = event.target.value;
      setstofp(stofpcopy);
    }
    if (j == "maximum") {
      const stofpcopy = Array.from(stofp);
      stofpcopy[idx][4] = event.target.value;
      setstofp(stofpcopy);
    }
    
    
  };


  const handleNewCalculations = (event, idx, j) => {
    if (j == "variables") {
      const calcycopy = Array.from(newcalcy);
      calcycopy[idx][0] = event.target.value;
      setnewcalcy(calcycopy);
    }
    
    if (j == "uom") {
      const calcycopy = Array.from(newcalcy);
      calcycopy[idx][1] = event.target.value;
      setnewcalcy(calcycopy);
    }
    if (j == "desc") {
      const calcycopy = Array.from(newcalcy);
      calcycopy[idx][2] = event.target.value;
      setnewcalcy(calcycopy);
    }
    if (j == "formula") {
      const calcycopy = Array.from(newcalcy);
      calcycopy[idx][3] = event.target.value;
      setnewcalcy(calcycopy);
    }
    
    
  };

  console.log("newcalcy",newcalcy);
  function deleteSnap(x, idx) {
    console.log("deleteSnap", idx);
    console.log("fp")
    const fpcopy = Array.from(fp);
    const addfprowcopy = Array.from(addfprow);
    fpcopy.splice(idx, 1);
    addfprowcopy.splice(idx, 1);
    console.log(fpcopy);
    console.log(addfprowcopy)
    setfp(fpcopy);
    //setgetValues(fpcopy);
    setaddfprow(addfprowcopy);
   
  }
  function deleteStoSnap(x, idx) {
    console.log("deleteStoSnap", idx);
    console.log("stofp")
    const stofpcopy = Array.from(stofp);
    const addstofprowcopy = Array.from(addstofprow);
    stofpcopy.splice(idx, 1);
    addstofprowcopy.splice(idx, 1);
    console.log(stofpcopy);
    console.log(addstofprowcopy)
    setstofp(stofpcopy);
    //setgetValues(fpcopy);
    setaddstofprow(addstofprowcopy);
   
  }

  function deleteCalcySnap(x, idx) {
    const newcalcycopy = Array.from(newcalcy);
    const addcalcycopy = Array.from(addcalcyrow);
    newcalcycopy.splice(idx, 1);
    addcalcycopy.splice(idx, 1);
    setnewcalcy(newcalcycopy);
    setaddcalcyrow(addcalcycopy);
   
  }
  function addfpin() {
    const fpcopy = Array.from(fp);
    const addfprowcopy = Array.from(addfprow);
    fpcopy.push(["","", "", "", "",""]);
    addfprowcopy.push(true);
    setaddfprow(addfprowcopy);
    setfp(fpcopy);
  }
  function addstofpin() {
    const stofpcopy = Array.from(stofp);
    const addstofprowcopy = Array.from(addstofprow);
    stofpcopy.push(["","", "", "", ""]);
    addstofprowcopy.push(true);
    setaddstofprow(addstofprowcopy);
    setstofp(stofpcopy);
  }

  function addcalculationsin() {
    const calcycopy = Array.from(newcalcy);
    const addcalcycopy = Array.from(addcalcyrow);
    calcycopy.push(["","", "", ""]);
    addcalcycopy.push(true);
    setaddcalcyrow(addcalcycopy);
    setnewcalcy(calcycopy);
  }



  console.log("storuns",storuns)
  function done(){
    setDone(true)
    handleCloseModal();
  }
  function stodone(){
    setStochasticDone(true)
    handleStoCloseModal();
  }
  function calculationsdone(){
    setCalculationsDone(true)
    handleCalculationsCloseModal();
  }



  function addDeExplicitRange(){
    if(expeditrange>-1){
      console.log('editable')
      var limitseqncopy = [...limitseqn]
      var limitscopy =[...limits]
      var expvarrangecopy = []
      var oldexpvarange = expvarrange
      var uomcopy = uom
      var remarkcopy = remark

      if(eachlimitequation!= ''){
        limitseqncopy[expeditrange] = eachlimitequation
        limitscopy[expeditrange][0] =eachmin
        limitscopy[expeditrange][1]=eachmax
      }
      setlimitseqn(limitseqncopy)
      setlimits(limitscopy)
      let varr = []
      for(var i = 0 ; i < (limitseqncopy.length);i++){
        var regex = /\b([a-zA-Z_]\w*)\b/g;
        var match;
        while ((match = regex.exec(limitseqncopy[i])) !== null) {
          if (!varr.includes(match[1])) {
          varr.push(match[1])
          }
        }
      }
      expvarrangecopy = expvarrangecopy.concat(varr);
      setexpvarrange(expvarrangecopy)
      let combined = expvar.concat(expvarrangecopy)
      settotalvar(combined)

      var uniqueIndices = [];
      expvarrangecopy.forEach(function(element, index) {
        // If the element is not in arr2, add its index to uniqueIndices
        if (!oldexpvarange.includes(element)) {
            uniqueIndices.push(index);
        }
    });
    uniqueIndices.forEach(function(index) {
      if (expvarrangecopy.length > oldexpvarange.length){
      uomcopy.splice(dependent.length + index,0,' ');
      remarkcopy.splice(dependent.length + index,0,' ')
      }
      });
      setUOM(uomcopy)
      setRemark(remarkcopy)
      seteachlimitEquation("");
      seteachmin("");
      seteachmax("");
      setexpeditrange(-1);
      handleCloseExplicitModalRange();

    }
    else{
      setlimits(prevLimits=>[...prevLimits,[eachmin,eachmax]])
      setlimitseqn(prevLimitsEqn=>[...prevLimitsEqn,eachlimitequation]);
      var regex = /\b([a-zA-Z_]\w*)\b/g;
      var match;
      let varr = []
      let expuomremark =[]
      while ((match = regex.exec(eachlimitequation)) !== null) {
        if (!varr.includes(match[1])) {
        varr.push(match[1])
        
        }
      }
    //console.log("varr",varr)
      let expvarcopy = expvarrange
      //console.log("copy",expvarcopy)
      for(var i = 0 ; i < (varr.length);i++){
        if (!expvarcopy.includes(varr[i])) {
          expvarcopy.push(varr[i])
          expuomremark.push(' ')
      }
    }
    setexpvarrange(expvarcopy)
    let combined = expvar.concat(expvarcopy)
      settotalvar(combined)

      setUOM(prevExpVar => [...prevExpVar, ...expuomremark]);
      setRemark(prevExpVar => [...prevExpVar, ...expuomremark])
      seteachmin("");
      seteachmax("");
      seteachlimitEquation("");
      handleCloseExplicitModalRange();
    }
    
  }
  /**console.log("expvar",expvar)
  console.log("expvarrange",expvarrange)
  console.log(uom)
  console.log(remark)
  console.log("limitseqn",limitseqn)
  console.log("limits",limits)**/

  
  const getdiscretedata = () => {
    setIsdiscreteLoading(true);
    var graphdatacopy = [];
    localStorage.setItem("projectID", pid);
    localStorage.setItem("projectName", pname);
    const token = localStorage.getItem("usertoken");
    fetch(`${baseURL}/getdiscretedata?projectID=${pid}&token=${token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())

      .then((data) => {
        if (data.discretevalues) {
          console.log("data",data)
            setruns(data.runs)
            const totalRuns = data.runs.reduce((acc, curr) => acc * curr, 1);
            settotalruns(totalRuns)
            //setgetValues(data.discretevalues);
            setfp(data.discretevalues)
            setDone(true);
            setTabledisplay(data.tabledisplaynew)
            setYdata(data.ydata);
            setkeys(data.varnames);
            setaddfprow(data.fprow);
            console.log("ingetdiscretedata")

            if (!(checkedState.length>0)){
              var falseArray = new Array(data.varnames.length).fill(false);
            setCheckedState(falseArray);
            console.log("checked",falseArray)
            }

            setIsdiscreteLoading(false);
          setEdit(data.editAccess)
          data.ydata.map((each, i) => {
            var randnum = Math.floor(Math.random() * (30 - 0 + 1)) + 0

           
              graphdatacopy.push({
                x: data.ydata[0].length,
                y: each,
                type: "line",
                mode: "lines+markers",
                marker: { color: colorslist[randnum] },
                name: data.varnames[i],
              });
            
          });
          setInitialGraph(graphdatacopy);
          setXlabel("Total Runs")
          setGraphdata([])
        } else {
          setIsdiscreteLoading(false);
          console.log("No data")
        }
      })
      .catch((err) => {
        console.log("caught it!", err);
        seterrmessage(true)
        setIsdiscreteLoading(false);
      });
  };
  console.log("fp",fp)
  console.log("addfprow",addfprow)

  const getstochasticdata = () => {
    setIsLoading(true);
    var graphdatacopy = [];
    localStorage.setItem("projectID", pid);
    localStorage.setItem("projectName", pname);
    const token = localStorage.getItem("usertoken");
    fetch(`${baseURL}/getstochasticdata?projectID=${pid}&token=${token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())

      .then((data) => {
        if (data.stochasticvalues) {
            console.log("stodata",data)
            setstoruns(data.storuns)
            //setgetValues(data.discretevalues);
            setstofp(data.stochasticvalues)
            setStochasticDone(true);
            setstoTabledisplay(data.tabledisplaynew)
            setstoYdata(data.stoydata);
            setstokeys(data.stovarnames);
            setaddstofprow(data.stofprow);
            console.log("getstochasticdata")

            if (!(stocheckedState.length>0)){
              var falseArray = new Array(data.stovarnames.length).fill(false);
            setstoCheckedState(falseArray);
            console.log("checked",falseArray)
            }

          setIsLoading(false);
          setEdit(data.editAccess)
          data.stoydata.map((each, i) => {
            var randnum = Math.floor(Math.random() * (30 - 0 + 1)) + 0

           
            graphdatacopy.push({
                x: data.stoydata[0].length,
                y: each,
                type: "line",
                mode: "lines+markers",
                marker: { color: colorslist[randnum] },
                name: data.stovarnames[i],
              });
            
          });
          setInitialstoGraph(graphdatacopy);
          
          setstoXlabel("Total Runs")
          
          setGraphstodata([])
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("caught it!", err);
        seterrmessage(true)
        setIsLoading(false);
      });
  };

  const getnewanalysisdata = () => {
    setIsLoading(true);
    var graphdatacopy = [];
    localStorage.setItem("projectID", pid);
    localStorage.setItem("projectName", pname);
    const token = localStorage.getItem("usertoken");
    fetch(`${baseURL}/getnewanalysisdata?projectID=${pid}&token=${token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())

      .then((data) => {
        if (data.calcytabledisplaynew) {
            console.log("stodata",data)
            setnewcalcy(data.newcalcy)
            setdatakeys(data.datakeys)
            setnewuom(result.newuom)
            setnewdesc(result.newdesc)
            setCalculationsDone(true);
            setcalcytabledisplaynew(data.calcytabledisplaynew)
            setSelectedValue(data.chosendata);
            setaddcalcyrow(data.addcalcyrow);
            console.log("getnewnalaysisdata")
            if (!(calcycheckedState.length>0)){
              var falseArray = new Array(data.datakeys.length).fill(true);
            setcalcyCheckedState(falseArray);
            }

            
          setIsLoading(false);
          setEdit(data.editAccess)
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("caught it!", err);
        seterrmessage(true)
        setIsLoading(false);
      });
  };


  const handleOpenModal = (val) => {
    if(val === "discrete"){
      setDone(false);
      setDiscreteModalOpen(true);

    }
    if(val === "stochastic"){
      setStochasticDone(false);
      setStochasticModalOpen(true);

    }
    if(val === "calculations"){
      setCalculationsDone(false);
      setModalCalculationsOpen(true);


    }
   
    
  };

  const handleOpenExplicitModal = () => {
    setModalExplicitOpen(true);
  };
  const handleOpenExplicitModalRange = () => {
    setModalExplicitOpenRange(true);
  };
  const handleCloseExplicitModal = () => {
    setModalExplicitOpen(false);
    setexpedit(-1)
  };
  const handleCloseExplicitModalRange = () => {
    setModalExplicitOpenRange(false);
    setexpeditrange(-1)
  };

  // Function to handle closing the modal
  
  const handleCloseModal = () => {
    //setfp(fp)
    //console.log("fp",fp)
    setDiscreteModalOpen(false);

  };
  const handleStoCloseModal = () => {
    //setfp(fp)
    //console.log("fp",fp)
    setStochasticModalOpen(false);

  };
  const handleCalculationsCloseModal = () => {
    //setfp(fp)
    //console.log("fp",fp)
    setModalCalculationsOpen(false);

  };

  const handleCloseExpUOMModal = () => {
    setModalExpUOMOpen(false);

  };
  const handleCloseExpEqnsModal = () => {
    setModalExpEqns(false);

  };
  const handleCloseExpRange = () => {
    setModalExpOpenRange(false);

  };

  function EditEquation(i){
    seteditableindex(i)
    handleOpenModal("yes")


  }
  function EditExplicit(i){
    setexpedit(i)
    handleOpenExplicitModal()


  }
  function EditExplicitRange(i){
    setexpeditrange(i)
    seteachlimitEquation(limitseqn[i])
    seteachmin(limits[i][0])
    seteachmax(limits[i][1])
    handleOpenExplicitModalRange()


  }

  function deleteEquation(i){

    var copyarr = [...equation];
  var depcopy = [...dependent];
  var deinitialcopy =[...deinitial];
  var uomcopy = [...uom];
  var remarkcopy = [...remark];
    depcopy.splice(i,1)
    copyarr.splice(i,1)
    deinitialcopy.splice(i,1)
    uomcopy.splice(i,1)
    remarkcopy.splice(i,1)


    setEquation(copyarr)
    setDependent(depcopy)
    setDeinitial(deinitialcopy)
    setUOM(uomcopy)
    setRemark(remarkcopy)

  };

  function deleteExplicit(i){
    var copyarr = [...explicit];
    copyarr.splice(i,1)
    var expvarcopy = []
    var newarr =[]
    var newremark = []

    let varr = []
      for(var i = 0 ; i < (copyarr.length);i++){
        var regex = /\b([a-zA-Z_]\w*)\b/g;
        var match;
        while ((match = regex.exec(copyarr[i])) !== null) {
          if (!varr.includes(match[1])) {
          varr.push(match[1])
          }
        }
      }

      expvarcopy = expvarcopy.concat(varr);
      setexpvar(expvarcopy)
      setExplicit(copyarr)

      newarr = uom.slice(0,(dependent.length+expvarcopy.length+expvarrange.length))
      newarr = newarr.concat(uom[uom.length-1])
      setUOM(newarr)

      newremark = remark.slice(0,(dependent.length+expvarcopy.length+expvarrange.length))
      newremark = newremark.concat(remark[remark.length-1])
      setRemark(newremark)
      let combined = expvarcopy.concat(expvarrange)
      settotalvar(combined)

  };

  function deleteExplicitRange(i){
    var limitseqncopy = [...limitseqn];
    var limitscopy=[...limits];
    limitseqncopy.splice(i,1)
    limitscopy.splice(i,1)
    var expvarrangecopy = []
    var newarr =[]
    var newremark = []
    let varr = []
    console.log("=====",limitseqncopy)
      for(var i = 0 ; i < (limitseqncopy.length);i++){
        var regex = /\b([a-zA-Z_]\w*)\b/g;
        var match;
        while ((match = regex.exec(limitseqncopy[i])) !== null) {
          if (!varr.includes(match[1])) {
          varr.push(match[1])
          }
        }
      }

      expvarrangecopy = expvarrangecopy.concat(varr);
      console.log("000000",expvarrangecopy)
      setexpvarrange(expvarrangecopy)
      let combined = expvar.concat(expvarrangecopy)
      settotalvar(combined)
      setlimitseqn(limitseqncopy)
      setlimits(limitscopy)

      newarr = uom.slice(0,(dependent.length+expvar.length+expvarrangecopy.length))
      newarr = newarr.concat(uom[uom.length-1])
      setUOM(newarr)
      newremark = remark.slice(0,(dependent.length+expvar.length+expvarrangecopy.length))
      newremark = newremark.concat(remark[remark.length-1])
      setRemark(newremark)


    /**
    let varr1 = []
      for(var l = 0 ; l < (limitseqncopy.length);l++){
        var regex = /\b([a-zA-Z_]\w*)\b/g;
        var match;
        while ((match = regex.exec(limitseqn[i])) !== null) {
          if (!varr1.includes(match[1])) {
          varr1.push(match[1])
          }
        }
      }
      console.log("varr1",varr1)
      varr1.forEach(element => {

      const index = expvarrangecopy.indexOf(element);
      expvarcopy.splice(index, 1);
      console.log("expvarcopynew",expvarcopy)
    });

    let varr2 = []
      for(var i = 0 ; i < (copyarr.length);i++){
        var regex = /\b([a-zA-Z_]\w*)\b/g;
        var match;
        while ((match = regex.exec(copyarr[i])) !== null) {
          if (!varr2.includes(match[1])) {
            varr2.push(match[1])
          }
        }
      }
      console.log("varr2",varr2)

      expvarcopy = expvarcopy.concat(varr2);
      console.log("expvar",expvarcopy)

      setexpvar(expvarcopy)
      setlimitseqn(copyarr)
      setlimits(limitscopy)

      newarr = uom.slice(0,(dependent.length+expvarcopy.length))
      newarr = newarr.concat(uom[uom.length-1])
      setUOM(newarr)
      console.log("uomm",newarr)


      newremark = remark.slice(0,(dependent.length+expvarcopy.length))
      newremark = newremark.concat(remark[remark.length-1])
      setRemark(newremark)
      console.log("remark",newremark) */


  };

  function addDe() {

    if(editableindex>-1){
      var eqcopy = [...equation]
      var dependentcopy=[...dependent]
      var deinitialcopy=[...deinitial]

      if(eachequation!= ''){
      eqcopy[editableindex] = eachequation
      }
      if(eachdependent!= ''){
      dependentcopy[editableindex] = eachdependent
      }
      if(eachdeinitial != ''){
      deinitialcopy[editableindex] = eachdeinitial
      }
      setEquation(eqcopy)
      setDependent(dependentcopy)
      setDeinitial(deinitialcopy)
      seteachDependent("");
      seteachEquation("");
      seteachDeinitial("");
      seteditableindex(-1);
      handleCloseModal();


      
    }
    else{
    setEquation([...equation, eachequation]);
    setDependent([...dependent, eachdependent]);
    setDeinitial([...deinitial, eachdeinitial]);
    setXlabel(independent)
    seteachDependent("");
    seteachEquation("");
    seteachDeinitial("");
    setUOM([...uom,' '])
    setRemark([...remark,' '])

    handleCloseModal();
    }
  }
  function savelimits(){
    console.log('yes')
  }

  function addDeExplicit() {
    if(expedit>-1){
      var expcopy = [...explicit]
      var expvarcopy = []
      var oldexpvar = expvar
      var uomcopy = uom
      var remarkcopy = remark
      if(eachexplicit!= ''){
        expcopy[expedit] = eachexplicit
      }
      setExplicit(expcopy)
      let varr = []
      for(var i = 0 ; i < (expcopy.length);i++){
        var regex = /\b([a-zA-Z_]\w*)\b/g;
        var match;
        while ((match = regex.exec(expcopy[i])) !== null) {
          if (!varr.includes(match[1])) {
          varr.push(match[1])
          }
        }
      }
      expvarcopy = expvarcopy.concat(varr);
      setexpvar(expvarcopy)
      let combined = expvarcopy.concat(expvarrange)
      settotalvar(combined)
      
      
      
      var uniqueIndices = [];
      expvarcopy.forEach(function(element, index) {
        // If the element is not in arr2, add its index to uniqueIndices
        if (!oldexpvar.includes(element)) {
            uniqueIndices.push(index);
        }
    });
    uniqueIndices.forEach(function(index) {
      if (expvarcopy.length > oldexpvar.length){
      uomcopy.splice(dependent.length + index,0,' ');
      remarkcopy.splice(dependent.length + index,0,' ')
      }
      });
      setUOM(uomcopy)
      setRemark(remarkcopy)


      seteachExplicit("");
      setexpedit(-1);
      
      handleCloseExplicitModal();


      
    }
    else{
    setExplicit([...explicit,eachexplicit]);
    seteachExplicit("");
    var regex = /\b([a-zA-Z_]\w*)\b/g;
    var match;
    let varr = []
    let expuomremark =[]  
    while ((match = regex.exec(eachexplicit)) !== null) {
      if (!varr.includes(match[1])) {
      varr.push(match[1])
      expuomremark.push(' ')
      }
    }
    setexpvar(prevExpVar => [...prevExpVar, ...varr]);
    setUOM(prevExpVar => [...prevExpVar, ...expuomremark]);
    setRemark(prevExpVar => [...prevExpVar, ...expuomremark])
    console.log("totalcopy",varr+expvarrange)
    let combined = varr.concat(expvarrange)
    settotalvar(combined)




    handleCloseExplicitModal();
    }
  }
  //console.log("total",totalvar)


  const handleermessageOpen = () => {
    seterrmessage(true);
  };
  const handleMessage = () => {
    seterrmessage(false);
  };
  
  function saveuom(i,value,id){
    
    if (id == "uom"){
      var uomcopy = uom;
    uomcopy[i] = value.trimStart()
    setUOM(uomcopy);
    seteachUom(value)

    }
    if(id ==="remark"){
      var remarkcopy = remark
      remarkcopy[i] = value.trimStart()
      seteachRemark(value)
      setRemark(remarkcopy);
    }
    if(id === "inuom"){
      var uomcopy = uom;

      uomcopy[uomcopy.length-1] = value.trimStart()
      setinuom(value);
      seteachUom(value)
      setUOM(uomcopy);
    }
    if(id ==="inremark"){
      var remarkcopy = remark
      remarkcopy[remarkcopy.length-1] = value.trimStart()

      setinRemark(value)
      seteachRemark(value)
      setRemark(remarkcopy);
    }
    handleCloseModal();
    
  }

  function downloaddiscretereports() {
    const projectID = localStorage.getItem("projectID");
    const projectName = localStorage.getItem("projectName");
    setIsLoading(true);
    fetch(`${baseURL}/downloaddiscretereports?projectID=${projectID}`)
      .then((res) => res.blob())
      .then((blob) => {
        var file = new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = projectName + '_discrete' +".xlsx";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetch(`${baseURL}/deletediscretefile?projectName=${projectName}`, {
          method: "POST",
          body: JSON.stringify(projectName),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error:", error);
            setIsLoading(false);
          });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        seterrormessage("Can't Download File")
        seterrmessage(true);
        console.log("Error", err);
      });

    /**
    const projectName = localStorage.getItem("projectName");
    console.log(ydata)
    console.log("called")
    var arr = [];

    for (var i = 0; i < ydata[0].length; i++) {
      var miniarr = []
      console.log("ydata",ydata)
      miniarr.push((ydata[(ydata.length) - 1][i]).toFixed(6));

      for (var j = 0; j < dependent.length; j++) {
          console.log("inside else")
          miniarr.push((ydata[j][i]).toFixed(6));        
      }

      arr.push(miniarr)
    }
    console.log(arr);
    
    var csv = "";
    csv += independent.toString() + ",";
    csv += dependent.toString();
    csv += "\n"
    console.log(csv);

    
    arr.forEach(function (row) {
      csv += row.join(",");
      csv += "\n";
    });
    console.log(csv);
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = projectName+".csv";
    hiddenElement.click();
     */
     

  }

  function downloadstochasticreports() {
    const projectID = localStorage.getItem("projectID");
    const projectName = localStorage.getItem("projectName");
    setIsLoading(true);
    fetch(`${baseURL}/downloadstochasticreports?projectID=${projectID}`)
      .then((res) => res.blob())
      .then((blob) => {
        var file = new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = projectName + '_stochastic' +".xlsx";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetch(`${baseURL}/deletestochasticfile?projectName=${projectName}`, {
          method: "POST",
          body: JSON.stringify(projectName),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error:", error);
            setIsLoading(false);
          });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        seterrormessage("Can't Download File")
        seterrmessage(true);
        console.log("Error", err);
      });

    
     

  }


  function downloadnewcalcyreports() {
    const projectID = localStorage.getItem("projectID");
    const projectName = localStorage.getItem("projectName");
    setIsLoading(true);
    fetch(`${baseURL}/downloadnewcalcyreports?projectID=${projectID}`)
      .then((res) => res.blob())
      .then((blob) => {
        var file = new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = projectName + '_LMD' +".xlsx";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetch(`${baseURL}/deletenewcalcyfile?projectName=${projectName}`, {
          method: "POST",
          body: JSON.stringify(projectName),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error:", error);
            setIsLoading(false);
          });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        seterrormessage("Can't Download File")
        seterrmessage(true);
        console.log("Error", err);
      });

    
     

  }


  function downloadselexmbreports() {
    const projectID = localStorage.getItem("projectID");
    const projectName = localStorage.getItem("projectName");
    

    setIsLoading(true);
    fetch(`${baseURL}/downloadselexmbreports?projectID=${projectID}&filteredArray=${calcycheckedState}`)
      .then((res) => res.blob())
      .then((blob) => {
        var file = new Blob([blob], {
          type: "text/csv", // Set MIME type for CSV
        });
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = projectName + '_SelexMB' + ".csv"; // Use .csv extension
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetch(`${baseURL}/deleteselexmbreports?projectName=${projectName}`, {
          method: "POST",
          body: JSON.stringify(projectName),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error:", error);
            setIsLoading(false);
          });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        seterrormessage("Can't Download File")
        seterrmessage(true);
        console.log("Error", err);
      });

    
     

  }

  function downloadaimbreport() {
    const projectID = localStorage.getItem("projectID");
    const projectName = localStorage.getItem("projectName");
    

    setIsLoading(true);
    fetch(`${baseURL}/downloadaimbreport?projectID=${projectID}&filteredArray=${calcycheckedState}`)
      .then((res) => res.blob())
      .then((blob) => {
        var file = new Blob([blob], {
          type: "text/csv", // Set MIME type for CSV
        });
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = projectName + '_AIMB' + ".csv"; // Use .csv extension
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetch(`${baseURL}/deleteaimbreports?projectName=${projectName}`, {
          method: "POST",
          body: JSON.stringify(projectName),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error:", error);
            setIsLoading(false);
          });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        seterrormessage("Can't Download File")
        seterrmessage(true);
        console.log("Error", err);
      });

    
     

  }

  let timer;
  const calculatenewanalysis = () => {
    console.log("calculatenewanalysis")
    const ProjectID = localStorage.getItem("projectID");
      setCalculatestart(true);
      setProgress(0);
      //setIsLoading(true);
  
      const config = {
        onUploadProgress: (progressEvent) => {
          let seconds = 400;
          timer = setInterval(() => {
            setProgress((prevProgress) =>
              prevProgress >= 99 ? 99 : prevProgress + 1
            );
          }, seconds);
        },
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        newcalcy: newcalcy,
        addcalcyrow: addcalcyrow,
        chosendata:selectedValue,
        ProjectID: ProjectID,
      })
  
        axios
        .post(`${baseURL}/calculatenewanalysis`, body, config)
          .then((res) => {
            const result = res.data;
            setIsLoading(false);
            if (result.myerror === 0){ 
              console.log(result)
              setcalcytabledisplaynew(result.calcytabledisplaynew)
              setdatakeys(result.datakeys)
              setnewuom(result.newuom)
              setnewdesc(result.newdesc)
              if (!(calcycheckedState.length>0)){
                var falseArray = new Array(result.datakeys.length).fill(true);
              setcalcyCheckedState(falseArray);
              }
            clearInterval(timer);
  
            setProgress(100);
          }
          else{
            setIsLoading(false);
            clearInterval(timer);
            setProgress(0);
  
            seterrormessage(result.message)
            seterrmessage(true)
          }
          })
          .catch((error) => {
            clearInterval(timer);
            seterrormessage("Something went wrong")
            seterrmessage(true);
            console.error("Error:", error);
          });
    }
  
  const calculatestochasticanalysis = () => {
  console.log("calculatestochasticanalysis")
  const ProjectID = localStorage.getItem("projectID");
    setCalculatestart(true);
    setProgress(0);
    //setIsLoading(true);
    var graphstodatacopy = [];

    const config = {
      onUploadProgress: (progressEvent) => {
        let seconds = 400;
        timer = setInterval(() => {
          setProgress((prevProgress) =>
            prevProgress >= 99 ? 99 : prevProgress + 1
          );
        }, seconds);
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      stofp: stofp,
      stofprow: addstofprow,
      storuns:storuns,
      ProjectID: ProjectID,
    })

      axios
      .post(`${baseURL}/calculatestochasticanalysis`, body, config)
        .then((res) => {
          const result = res.data;
          setIsLoading(false);
          if (result.myerror === 0){ 
            console.log(result)
            setstoruns(result.storuns)
            setstofp(result.stochasticvalues);
            setstoTabledisplay(result.stotabledisplaynew)
            setstoYdata(result.stoydata);
            setstokeys(result.stovarnames);
            setaddstofprow(result.stofprow);
            if (!(stocheckedState.length>0)){
              var falseArray = new Array(result.stovarnames.length).fill(false);
            setstoCheckedState(falseArray);
            console.log("checked",falseArray)
            }
            
            result.stoydata.map((each, i) => {
              var randnum = Math.floor(Math.random() * (30 - 0 + 1)) + 0
  
             
                graphstodatacopy.push({
                  x: result.stoydata[0].length,
                  y: each,
                  type: "line",
                  mode: "lines+markers",
                  marker: { color: colorslist[randnum] },
                  name: result.stovarnames[i],
                });
              
            });
            setInitialstoGraph(graphstodatacopy);
            setstoXlabel("Total Runs")
            
          clearInterval(timer);

          setProgress(100);
        }
        else{
          setIsLoading(false);
          clearInterval(timer);
          setProgress(0);

          seterrormessage(result.message)
          seterrmessage(true)
        }
        })
        .catch((error) => {
          clearInterval(timer);
          seterrormessage("Something went wrong")
          seterrmessage(true);
          console.error("Error:", error);
        });
  }

  const calculatediscreteanalysis = () => {
    const ProjectID = localStorage.getItem("projectID");
    setCalculatediscretestart(true);
    setProgress(0);
    //setIsLoading(true);
    var graphdatacopy = [];

    const config = {
      onUploadProgress: (progressEvent) => {
        let seconds = 400;
        timer = setInterval(() => {
          setProgress((prevProgress) =>
            prevProgress >= 99 ? 99 : prevProgress + 1
          );
        }, seconds);
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      fp: fp,
      fprow: addfprow,
      ProjectID: ProjectID,
    })

      axios
      .post(`${baseURL}/calculatediscreteanalysis`, body, config)
        .then((res) => {
          const result = res.data;
          setIsLoading(false);
          if (result.myerror === 0){ 
            console.log(result)
            setruns(result.runs)
            console.log(result.runs)
            const totalRuns = result.runs.reduce((acc, curr) => acc * curr, 1);
            settotalruns(totalRuns)

            setfp(result.discretevalues);
            setTabledisplay(result.tabledisplaynew)
            setYdata(result.ydata);
            setkeys(result.varnames);
            if (!(checkedState.length>0)){
              var falseArray = new Array(result.varnames.length).fill(false);
            setCheckedState(falseArray);
            console.log("checked",falseArray)
            }
            
            result.ydata.map((each, i) => {
              var randnum = Math.floor(Math.random() * (30 - 0 + 1)) + 0
  
             
                graphdatacopy.push({
                  x: result.ydata[0].length,
                  y: each,
                  type: "line",
                  mode: "lines+markers",
                  marker: { color: colorslist[randnum] },
                  name: result.varnames[i],
                });
              
            });
            setInitialGraph(graphdatacopy);
            setXlabel("Total Runs")
            /**
          setTabledisplay(result.tabledisplaynew);
          
          setallvalues(result.allvalues);
          setkeys(result.keysofgraph);
          setX(result.allvalues[result.keysofgraph[(result.keysofgraph.length)-1]])

          var totalallvars=dependent.concat(totalvar)
          totalallvars=totalallvars.concat(independent)
          console.log("totalallvars",totalallvars)
          var alluomscopy = []
          for (var k=0;k<result.keysofgraph.length;k++){
            var indexxx = totalallvars.indexOf(result.keysofgraph[k])
            alluomscopy.push(uom[indexxx])
             
          }
          setalluoms(alluomscopy)
          console.log("alluomscopy",alluomscopy)
          var labelform = independent +" ("+uom[(uom.length)-1]+")"
          setXlabel(labelform)

          console.log("length",result.keysofgraph.length)
          
          if (!(checkedState.length>0)){
            var falseArray = new Array(result.keysofgraph.length).fill(false);
          setCheckedState(falseArray);
          console.log("checked",falseArray)
          }
          
          result.ydata.map((each, i) => {
            var randnum = Math.floor(Math.random() * (30 - 0 + 1)) + 0

            if (i != result.ydata.length - 1) {
              graphdatacopy.push({
                x: result.ydata[(result.ydata.length)-1],
                y: each,
                type: "line",
                mode: "lines+markers",
                marker: { color: colorslist[randnum] },
                name: dependent[i] + "(" + uom[i] + ")",
              });
            }
          });
          setInitialGraph(graphdatacopy);
          */
          clearInterval(timer);

          setProgress(100);
        }
        else if (result.myerror === 2){
            setruns(result.runs)
            const totalRuns = result.runs.reduce((acc, curr) => acc * curr, 1);
            settotalruns(totalRuns)

            setfp(result.discretevalues);
            setkeys(result.varnames);
            clearInterval(timer);
            setProgress(0);
            setCalculatediscretestart(false);

            seterrormessage(result.message)
            seterrmessage(true)
        }
        else{
          setIsLoading(false);
          clearInterval(timer);
          setProgress(0);

          seterrormessage(result.message)
          seterrmessage(true)
        }
        })
        .catch((error) => {
          clearInterval(timer);
          seterrormessage("Something went wrong")
          seterrmessage(true);
          console.error("Error:", error);
        });
       
  };
  const operatorsData = [
    { operator: '()', name: 'Brackets', example: '(a + b)/2' },
    { operator: '+', name: 'Addition', example: 'a + b' },
    { operator: '-', name: 'Subtraction', example: 'a - b' },
    { operator: '*', name: 'Multiplication', example: 'a * b' },
    { operator: '/', name: 'Division', example: 'a / b' },
    { operator: '%', name: 'Modulus', example: 'a % b' },
    { operator: '**', name: 'Exponentiation', example: 'a ** b' },
    { operator: '//', name: 'Floor Division', example: 'a // b' },
    // Add more operators as needed
  ];
  
  const functionsData = [
    { fnname: 'abs', name: 'Absolute Value', example: 'abs(x)' },
    { fnname: 'sqrt', name: 'Square Root', example: 'sqrt(x)' },
    { fnname: 'pow', name: 'Exponentiation', example: 'pow(x, y)' },
    { fnname: 'exp', name: 'Exponential', example: 'exp(x)' },
    { fnname: 'ln', name: 'Natural logarithm', example: 'log(x)' },
    { fnname: 'log', name: 'Logarithm', example: 'log(x, base)' },
    { fnname: 'sin', name: 'Sine', example: 'sin(x)' },
    { fnname: 'cos', name: 'Cosine', example: 'cos(x)' },
    { fnname: 'tan', name: 'Tangent', example: 'tan(x)' },
    { fnname: 'asin', name: 'Arcsine', example: 'asin(x)' },
    { fnname: 'acos', name: 'Arccosine', example: 'acos(x)' },
    { fnname: 'atan', name: 'Arctangent', example: 'atan(x)' },
    { fnname: 'floor', name: 'Floor', example: 'floor(x)' },
    { fnname: 'ceil', name: 'Ceiling', example: 'ceil(x)' },

    // Add more functions as needed
  ];
  return (
    <div>
      <div className="popup">
      <Dialog
        open={info}
        onClose={handleCloseinfo}
        
      >
        <DialogTitle sx={{fontSize:"14px", color: Projectcolors.primary}}>Available Expressions and Functions</DialogTitle>
<DialogContent sx={{padding:'20px'}}>
<AppBar position="static"  >
        <Tabs  onChange={handleTabChange}>
          <Tab label="Mathematical Operators" value={0} style={{color:'white',fontSize:"13px" , textTransform: 'capitalize'}}/>
          <Tab label="Mathematical Functions" value={1} style={{color:'white',fontSize:"13px" , textTransform: 'capitalize'}} />
        </Tabs>
      </AppBar>
      <Paper elevation={3} style={{ padding: '10px' }}>
        {selectedTab === 0 && (
          <div>
            <table>
              <tbody>
                {operatorsData.map((item, index) => (
                  <tr key={index}>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.operator}</td>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.name}</td>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.example}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <hr />
            <p style={{fontSize:'13px',fontWeight:'bold'}}>Example :  </p>
            <p style={{fontSize:'13px'}}>x1 = b + c</p>
            <p style={{fontSize:'13px'}}>x2 = ( b // c ) * x1</p>
            <p style={{fontSize:'13px'}}>x3= x1 + x2</p>
          </div>
        )}
        {selectedTab === 1 && (
          <div>
            <table>
              <tbody>
                {functionsData.map((item, index) => (
                  <tr key={index}>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.fnname}</td>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.name}</td>
                    <td style={{padding:'10px',fontSize: "13PX"}}>{item.example}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <hr/>
            <p style={{fontSize:'13px',fontWeight:'bold'}}>Example :</p>
            <p style={{fontSize:'13px'}}> Y = log(x) + sin(x)</p>

          </div>
        )}
      </Paper>
      </DialogContent>
        
        
      </Dialog>
    </div>
      <Dialog
        open={errmessage}
        onClose={handleMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{padding:5}}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{  fontSize: "14px" }}
        >
          <Typography sx={{color: Projectcolors.primary,fontSize: "16px",fontWeight:'bold'}}>Selex Debugger</Typography>
          <Typography sx={{color: "red" , fontSize: "14px",marginTop:2}}>Error !! {errormessage}</Typography>
        </DialogTitle>
      </Dialog>

      <SelexAnalysisNavbar />

      <Box
        bgcolor="white"
        p={2}
        boxShadow={3}
        borderRadius={2}
        m="auto"
        sx={{
          width: "1300px",
          marginTop: "20px",
          height: "870px",
          marginBottom: "50px",
        }}
      >
        <Typography sx={{ fontSize: 16 }}>
          Lean Model Dynamics
        </Typography>

        <Box display="flex" justifyContent="space-between">
          {/* First box */}
          <Box
            width="48%"
            height={800}
            padding={1}
            elevation={10}
            marginTop={2}
            boxShadow={3}
            border={0}
          >
            {/* Content of the first box */}
            
            <Box border={0}>
            <TabContext value={value}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Discrete Analysis"
                  value="1"
                  sx={{
                    fontSize: 14,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                />
                 <Tab
                  label="Stochastic Analysis"
                  value="2"
                  sx={{
                    fontSize: 14,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                />
                
               
                <Tab
                  label="First Principles Analysis"
                  value="3"
                  sx={{
                    fontSize: 14,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                />
              
              
              </TabList>
             
          </Box>
          <TabPanel value="1">
          <Button
              variant="contained"
              color="primary"
              onClick={()=>handleOpenModal("discrete")}
              disabled = {edit?false:true}
              //disabled={props.editAccess}
            >
              <ZoomIn
                            sx={{ fontSize: 15 , cursor: "pointer",marginLeft:12,marginRight:12,marginTop:2,display:'inline',border:1 , strokeWidth: 1   }}
                            
                          /> Add Variable
            </Button>
            
              <Box
                sx={{
                  elevation: 3,
                  border: 0,
                  padding: 0,
                  fontSize: 14,
                  marginTop: 2,
                }}
              >
                {" "}
                <TableContainer component={Paper} sx={{ height: 220 }}>
              <Table
                sx={{ minWidth: 495 }}
                aria-label="sticky table"
                stickyHeader
             >
                <TableHead
                  sx={{
                    backgroundColor: Projectcolors.primary,
                  }}
                >
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Variable
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      UOM
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Minimum
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Maximum
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Step
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Runs
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                {" "}
                {fp.length > 0 && isdone == true?
                   fp.map((row, i) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            {row[0]}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            {row[1]}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            {row[2]}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            {row[3]}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            {row[4]}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            {row[5]}
                          </TableCell>

                        
                        

                      {(addfprow[i] == true && runs.length==0) ? (
                         <TableCell
                         sx={{ fontSize: "10px", height: 1 }}
                         align="center"
                       >
                         0
                       </TableCell>
                          
                        ) : (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            {runs[i]}
                          </TableCell>
                        )}

                        
                        {addfprow[i] == true ? (
                          <TableCell>
                            <DeleteIcon
                              onClick={() => deleteSnap(0, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color: Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell>
                            <DeleteIcon
                            onClick={() => deleteSnap(0, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color: Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    )):null}

              </Table>
            </TableContainer>
              </Box>
              <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 1,marginLeft:0 }}
              onClick={calculatediscreteanalysis}
              disabled = {edit ?false:true}
            >
              Calculate
            </Button>
            <Typography sx={{mt:2,fontSize:14}}>
              Total Runs : {totalruns}
              </Typography>

{calculatediscretestart ? (
                  <Box
                    sx={{
                      width: "93%",
                      float: "left",
                      marginBottom: 0,
                      height: "10px",
                      marginTop: "5px",
                      marginLeft:0,
                      border:0
                    }}
                  >
                    <LinearProgressWithLabel
                      value={progress}
                      sx={{ height: "6px" }}
                    />
                  </Box>
                ) : null}
             <Box sx={{ marginTop: 3 ,borderTop:2,width:550,marginLeft:0}}>
            <Typography sx={{ fontSize: 14, marginTop: 0.5 }}>
              Graphical Representation
            </Typography>
            <Box display="flex" width="150px" sx={{border:0,mt:2}}>
            {keys.length>0?(
              <Box sx={{display:"inline" , padding:1,border:0,width:"150px"}} elevation ={3} component={Paper}>

              
            <Typography sx={{ fontSize: 12 , mt:1,ml:1.5 }}>Y axis</Typography>

           <Box sx={{ height: "260px", overflowY: "auto" ,overflowX:"hidden",border:0,width:"130px" }}>         
      {keys.map((each,i)=>(

          <Box sx={{ mt: 2 }}>
           
          <Checkbox
            color="primary"
            inputProps={{
              "aria-label": "select all desserts",
            }}
            sx={{ display: "inline" }}
            size="medium"
            key={0}
            value={"linear"}
            checked={checkedState[i]}
            onChange={() => handleOnChange(each, checkedState[i],i)}
          />
          <Typography sx={{ fontSize: "12px", display: "inline" }}>
          {each.length > 9 ? each.slice(0, 9) + "..." : each}
          </Typography>
          </Box>
      
      )

      )}
      </Box>
       </Box>
            ):null}
            
           
            <Box sx={{display:"inline",border:0,ml:1}} elevation ={3} component={Paper} >
            
            
            
            {ydata.length > 0 ? (
              <Plot
                data={graphdata.length>0?graphdata:initialgraph}
                layout={{
                  width: 410,
                  height: 300,
                  marginTop: 0,
                  title: {
                    font: {
                      family: "Courier New, monospace",
                      size: 24,
                    },
                    xref: "paper",
                    x: 0.05,
                  },
                  xaxis: {
                    title: {
                      text: xlabel,
                      font: {
                        size: 14,
                        color: "#000",
                      },
                    },
                  },
                  yaxis: {
                    title: {
                      text: graphdata.length === 1 ? records[0] : "Variables",
                      font: {
                        size: 14,
                        color: "#000",
                      },
                    },
                  },
                }}
                config={{
                  displaylogo: false,
                }}
              />
            ) : null}
            </Box>
            </Box>
            </Box>

             
             
          </TabPanel>
          <TabPanel value="2">
          <Button
              variant="contained"
              color="primary"
              onClick={()=>handleOpenModal("stochastic")}
              disabled = {edit?false:true}
              //disabled={props.editAccess}
            >
              <ZoomIn
                            sx={{ fontSize: 15 , cursor: "pointer",marginLeft:12,marginRight:12,marginTop:2,display:'inline',border:1 , strokeWidth: 1   }}
                            
                          /> Add Variable
            </Button>
            
              <Box
                sx={{
                  elevation: 3,
                  border: 0,
                  padding: 0,
                  fontSize: 14,
                  marginTop: 2,
                }}
              >
                {" "}
                <TableContainer component={Paper} sx={{ height: 220,marginBottom:2 }}>
              <Table
                sx={{ minWidth: 495 }}
                aria-label="sticky table"
                stickyHeader
             >
                <TableHead
                  sx={{
                    backgroundColor: Projectcolors.primary,
                  }}
                >
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Variable
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      UOM
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Minimum
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Maximum
                    </TableCell>
                    
                    
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                {" "}
                {stofp.length > 0 && isstodone == true?
                   stofp.map((row, i) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            {row[0]}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            {row[1]}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            {row[2]}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            {row[3]}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            {row[4]}
                          </TableCell>
                        
                        {addstofprow[i] == true ? (
                          <TableCell
                          align="center">
                            <DeleteIcon
                              onClick={() => deleteStoSnap(0, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "center",
                                marginTop: 1,
                                marginRight: 2,
                                color: Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell
                          align="center">
                            <DeleteIcon
                            onClick={() => deleteStoSnap(0, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "center",
                                marginTop: 1,
                                marginRight: 2,
                                color: Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    )):null}

              </Table>
            </TableContainer>
            <Typography sx={{fontSize:13,display:"inline",marginTop:2}}>Number of Runs</Typography>
               <FormControl
                              sx={{
                                display: "inline",
                                marginRight: 0,
                                marginTop: 2,
                                marginLeft: 2,
                                width: 100,
                              }}
                              size="small"
                            >
                            
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={storuns}
                                onChange={(e) => {
                                  setstoruns(e.target.value);
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
              </Box>
              

             <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 0,marginLeft:0 }}
              onClick={calculatestochasticanalysis}
              disabled = {edit?false:true}
            >
              Calculate
            </Button>
            {calculatestart ? (
                  <Box
                    sx={{
                      width: "93%",
                      float: "left",
                      marginBottom: 0,
                      height: "10px",
                      marginTop: "5px",
                      marginLeft:0,
                      border:0
                    }}
                  >
                    <LinearProgressWithLabel
                      value={progress}
                      sx={{ height: "6px" }}
                    />
                  </Box>
                ) : null}


             <Box sx={{ marginTop: 3 ,borderTop:2,width:550,marginLeft:0}}>
            <Typography sx={{ fontSize: 14, marginTop: 0.5 }}>
              Stochastic Graphical Representation
            </Typography>
            <Box display="flex" width="150px" sx={{border:0,mt:1}}>
            {stokeys.length>0?(
              <Box sx={{display:"inline" , padding:1,border:0,width:"150px"}} elevation ={3} component={Paper}>

              
            <Typography sx={{ fontSize: 12 , mt:1,ml:1.5 }}>Y axis</Typography>

           <Box sx={{ height: "240px", overflowY: "auto" ,overflowX:"hidden",border:0,width:"130px" }}>         
      {stokeys.map((each,i)=>(

          <Box sx={{ mt: 2 }}>
           
          <Checkbox
            color="primary"
            inputProps={{
              "aria-label": "select all desserts",
            }}
            sx={{ display: "inline" }}
            size="medium"
            key={0}
            value={"linear"}
            checked={stocheckedState[i]}
            onChange={() => handleOnstoChange(each, stocheckedState[i],i)}
          />
          <Typography sx={{ fontSize: "12px", display: "inline" }}>
          {each.length > 9 ? each.slice(0, 9) + "..." : each}
          </Typography>
          </Box>
      
      )

      )}
      </Box>
       </Box>
            ):null}
            
           
            <Box sx={{display:"inline",border:0,ml:1}} elevation ={3} component={Paper} >
            
            
            
            {stoydata.length > 0 ? (
              <Plot
                data={graphstodata.length>0?graphstodata:initialstograph}
                layout={{
                  width: 410,
                  height: 300,
                  marginTop: 0,
                  title: {
                    font: {
                      family: "Courier New, monospace",
                      size: 24,
                    },
                    xref: "paper",
                    x: 0.05,
                  },
                  xaxis: {
                    title: {
                      text: stoxlabel,
                      font: {
                        size: 14,
                        color: "#000",
                      },
                    },
                  },
                  yaxis: {
                    title: {
                      text: graphstodata.length === 1 ? storecords[0] : "Variables",
                      font: {
                        size: 14,
                        color: "#000",
                      },
                    },
                  },
                }}
                config={{
                  displaylogo: false,
                }}
              />
            ) : null}
            </Box>
            </Box>
            </Box>
             
          </TabPanel>

          <TabPanel value="3">  
          <Button
              variant="contained"
              color="primary"
              onClick={()=>handleOpenModal("calculations")}
              disabled = {edit?false:true}
              //disabled={props.editAccess}
            >
              <ZoomIn
                            sx={{ fontSize: 15 , cursor: "pointer",marginLeft:12,marginRight:12,marginTop:2,display:'inline',border:1 , strokeWidth: 1   }}
                            
                          /> Add New Variable
            </Button>

            <Box
                sx={{
                  elevation: 3,
                  border: 0,
                  padding: 0,
                  fontSize: 14,
                  marginTop: 2,
                }}
              >
                {" "}
                <TableContainer component={Paper} sx={{ height: 220,marginBottom:2 }}>
              <Table
                sx={{ minWidth: 495 }}
                aria-label="sticky table"
                stickyHeader
             >
                <TableHead
                  sx={{
                    backgroundColor: Projectcolors.primary,
                  }}
                >
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Variable
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      UOM
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Formula
                    </TableCell>
                    
                    
                    
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                {" "}
                {newcalcy.length > 0 && iscalculationsdone == true?
                   newcalcy.map((row, i) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            {row[0]}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            {row[1]}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            {row[2]}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            {row[3]}
                          </TableCell>
                         
                        {addcalcyrow[i] == true ? (
                          <TableCell
                          align="center">
                            <DeleteIcon
                              onClick={() => deleteCalcySnap(0, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "center",
                                marginTop: 1,
                                marginRight: 2,
                                color: Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell
                          align="center">
                            <DeleteIcon
                            onClick={() => deleteCalcySnap(0, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "center",
                                marginTop: 1,
                                marginRight: 2,
                                color: Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    )):null}

              </Table>
            </TableContainer>
            
              </Box>
              

           
                 <Box sx={{ marginTop: 3 ,borderTop:2,width:550,marginLeft:0}}>
            <Typography sx={{ fontSize: 14, marginTop: 0.5 }}>
              Tabular Representation
            </Typography>
            <Box display="flex" width="500px" sx={{border:0,mt:1}}>
            <Box sx={{display:"inline" , padding:1,border:0,width:"250px"}} elevation ={3} component={Paper}>
            <FormControl component="fieldset" sx={{marginLeft:2}}>
            <Typography sx={{fontSize:13,marginTop:2}}>Select Data</Typography>

      <RadioGroup
        aria-label="options"
        name="options"
        value={selectedValue}
        sx={{fontSize:"14px"}}
        onChange={handleRadioChange}
        row
      >
        <FormControlLabel value="discrete" control={<Radio />} label="Discrete Data" sx={{ '& .MuiFormControlLabel-label': { fontSize: '1.2rem' } }} />
        <FormControlLabel value="stochastic" control={<Radio />} label="Stochastic Data" sx={{ '& .MuiFormControlLabel-label': { fontSize: '1.2rem' } }} />
      </RadioGroup>
    </FormControl>
              </Box>

            {datakeys.length>0?(
              <Box sx={{display:"inline" , padding:1,border:0,width:"250px",marginLeft:2}} elevation ={3} component={Paper}>

              
<Typography sx={{fontSize:13,marginTop:2}}>Tabular Representation Variables</Typography>

           <Box sx={{ height: "240px", overflowY: "auto" ,overflowX:"hidden",border:0,width:"200px" }}>         
      {datakeys.map((each,i)=>(

          <Box sx={{ mt: 2 }}>
           
          <Checkbox
            color="primary"
            inputProps={{
              "aria-label": "select all desserts",
            }}
            sx={{ display: "inline" }}
            size="medium"
            key={0}
            value={"linear"}
            checked={calcycheckedState[i]}
            onChange={() => handleOnCalcyChange(each, calcycheckedState[i],i)}
          />
          <Typography sx={{ fontSize: "12px", display: "inline" }}>
          {each.length > 9 ? each.slice(0, 9) + "..." : each}
          </Typography>
          </Box>
      
      )

      )}
      </Box>
       </Box>
            ):              <Box sx={{display:"inline" , padding:1,border:0,width:"250px",marginLeft:2}} elevation ={3} component={Paper}>

              
            <Typography sx={{fontSize:13,marginTop:2}}>Tabular Representation Variables</Typography>
            </Box>}
            



            
           
           
            </Box>

            </Box>




            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 2,marginLeft:0 }}
              onClick={calculatenewanalysis}
              disabled = {edit?false:true}
            >
              Calculate
            </Button>
            {calculatestart ? (
                  <Box
                    sx={{
                      width: "93%",
                      float: "left",
                      marginBottom: 0,
                      height: "10px",
                      marginTop: "5px",
                      marginLeft:0,
                      border:0
                    }}
                  >
                    <LinearProgressWithLabel
                      value={progress}
                      sx={{ height: "6px" }}
                    />
                  </Box>
                ) : null}


            

          </TabPanel>


            

          
        </TabContext>
        

            
            </Box>
           

          
          </Box>

          {/* Second box */}
          <Box
            width="51%"
            height={800}
            padding={3}
            elevation={10}
            marginTop={2}
            boxShadow={3}
          >
            <TabContext value={reportvalue}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TabList
                onChange={handleReportChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Discrete Report"
                  value="4"
                  sx={{
                    fontSize: 14,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                />
                 <Tab
                  label="Stochastic Report"
                  value="5"
                  sx={{
                    fontSize: 14,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                />
                <Tab
                  label="LMD Report"
                  value="6"
                  sx={{
                    fontSize: 14,
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                />
                
              </TabList>
              </Box>
              <TabPanel value="4">
          <Box sx={{ marginTop: 0,border:0 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
               <img
                                                    alt ="arrow"
                                                    src={Excel}
                                                    style={{
                                                      marginTop: 0,
                                                      marginBottom: 1,
                                                      marginRight: 1,
                                                      width:30,
                                                      height:30
                                                    }}
                                                  />
              <Typography sx={{ fontSize: 14 , marginTop:0.5,marginLeft:0.5 }}>
                Download Discrete Report
              </Typography>
              <DownloadIcon
                sx={{
                  fontSize: "medium",
                  marginTop: 1,
                  marginRight: 2,
                  marginLeft:1,
                  color: Projectcolors.primary,
                }}
                onClick={() => downloaddiscretereports()}
              />
              </Box>
              <Box sx={{ marginTop: 2 ,border:0,width:550,marginLeft:0}}>
              <Typography sx={{ fontSize: 14 }}>
                Tabular Representation
              </Typography>
              <TableContainer
                component={Paper}
                sx={{ height: 600, marginTop: 1 }}
              >
                <Table sx={{ minWidth: 195 }}>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: Projectcolors.primary,
                    }}
                  >
                    <TableRow>
                    {keys.length > 0
                      ?keys.map((each,k) => (
                      <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                        {each}
                      </TableCell>
                      )):(
                        <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                       Variables
                      </TableCell>
                      )}
                     
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tabledisplay.length > 0
                      ? tabledisplay.map((each,k) => (
                          <TableRow>
                            {each.map((value,j)=>(
                              <TableCell
                              align="center"
                              sx={{ fontSize: "10px", height: 1 }}
                            >
                              {value.toString().length>5?(Number(value).toFixed(5)):value}
                            </TableCell>
                            ))}
                            
                          
                           
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            </Box>
            </TabPanel>
            <TabPanel value="5">
          <Box sx={{ marginTop: 0,border:0 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <img
                                                    alt ="arrow"
                                                    src={Excel}
                                                    style={{
                                                      marginTop: 0,
                                                      marginBottom: 1,
                                                      marginRight: 1,
                                                      width:30,
                                                      height:30
                                                    }}
                                                  />
              <Typography sx={{ fontSize: 14 ,marginTop:0.5,marginLeft:0.5}}>
                Download Stochastic Report
              </Typography>

              <DownloadIcon
                sx={{
                  fontSize: "medium",
                  marginTop: 1,
                  marginRight: 2,
                  marginLeft:1,
                  color: Projectcolors.primary,
                }}
                onClick={() => downloadstochasticreports()}
              />
              </Box>
              <Box sx={{ marginTop: 2 ,border:0,width:550,marginLeft:0}}>
              <Typography sx={{ fontSize: 14 }}>
                Tabular Representation
              </Typography>
              <TableContainer
                component={Paper}
                sx={{ height: 600, marginTop: 1 }}
              >
                <Table sx={{ minWidth: 195 }}>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: Projectcolors.primary,
                    }}
                  >
                    <TableRow>
                    {stokeys.length > 0
                      ?stokeys.map((each,k) => (
                      <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                        {each}
                      </TableCell>
                      )):(
                        <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                       Variables
                      </TableCell>
                      )}
                     
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stotabledisplay.length > 0
                      ? stotabledisplay.map((each,k) => (
                          <TableRow>
                            {each.map((value,j)=>(
                              <TableCell
                              align="center"
                              sx={{ fontSize: "10px", height: 1 }}
                            >
                              {value.toString().length>5?(Number(value).toFixed(5)):value}
                            </TableCell>
                            ))}
                            
                          
                           
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            </Box>
            </TabPanel>
            <TabPanel value="6">
          <Box sx={{ marginTop: 0,border:0 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
               <img
                                                    alt ="arrow"
                                                    src={Excel}
                                                    style={{
                                                      marginTop: 0,
                                                      marginBottom: 1,
                                                      marginRight: 1,
                                                      width:30,
                                                      height:30
                                                    }}
                                                  />
              
              <Typography sx={{ fontSize: 14 , marginTop:0.5,marginLeft:0.5 }}>
                Download LMD Report
              </Typography>
              <DownloadIcon
                sx={{
                  fontSize: "medium",
                  marginTop: 1,
                  marginRight: 2,
                  marginLeft:1,
                  color: Projectcolors.primary,
                }}
                onClick={() => downloadnewcalcyreports()}
              />
              </Box>
              <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop:1
              }}
            >
               <img
                                                    alt ="arrow"
                                                    src={selexmb}
                                                    style={{
                                                      marginTop: 0,
                                                      marginBottom: 1,
                                                      marginRight: 1,
                                                      width:30,
                                                      height:30
                                                    }}
                                                  />
              <Typography sx={{ fontSize: 14 , marginTop:0.5,marginLeft:0.5 }}>
                Download SelexMB Template
              </Typography>
              <DownloadIcon
                sx={{
                  fontSize: "medium",
                  marginTop: 1,
                  marginRight: 2,
                  marginLeft:1,
                  color: Projectcolors.primary,
                }}
                onClick={() => downloadselexmbreports()}
              />
              </Box>
              <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop:1
              }}
            >
              <img
                                                    alt ="arrow"
                                                    src={aimb}
                                                    style={{
                                                      marginTop: 0,
                                                      marginBottom: 1,
                                                      marginRight: 1,
                                                      width:30,
                                                      height:30
                                                    }}
                                                  />
              <Typography sx={{ fontSize: 14 , marginTop:0.5,marginLeft:0.5 }}>
                Download AIMB Template
              </Typography>
              <DownloadIcon
                sx={{
                  fontSize: "medium",
                  marginTop: 1,
                  marginRight: 2,
                  marginLeft:1,
                  color: Projectcolors.primary,
                }}
                onClick={() => downloadaimbreport()}
              />
              </Box>
              <Box sx={{ marginTop: 2 ,border:0,width:550,marginLeft:0}}>
              <Typography sx={{ fontSize: 14 }}>
                Tabular Representation
              </Typography>
              <TableContainer
                component={Paper}
                sx={{ height: 530, marginTop: 1 }}
              >
                <Table sx={{ minWidth: 195 }}>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: Projectcolors.primary,
                    }}
                  >
                    <TableRow>
                    {datakeys.length > 0
  ? datakeys.map((each, k) => (
      calcycheckedState[k] ? (
        <TableCell
          key={k}
          align="center"
          sx={{ fontSize: "12px", color: "white" }}
        >
          {each}
        </TableCell>
      ) : null
    ))
  : (
    <TableCell
      align="center"
      sx={{ fontSize: "12px", color: "white" }}
    >
      Variables
    </TableCell>
  )
}

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {calcytabledisplaynew.length > 0
                      ? calcytabledisplaynew.map((each,k) => (
                          <TableRow>
                            {each.map((value,j)=>(
                              calcycheckedState[j] ? (
                              <TableCell
                              align="center"
                              sx={{ fontSize: "10px", height: 1 }}
                            >
                              {value.toString().includes('*I') 
    ? parseFloat(value.split('*')[0]).toFixed(5) 
    : (value.toString().length > 5 && !isNaN(Number(value)) 
        ? Number(value).toFixed(5) 
        : value)}
                            </TableCell>
                              ):(null)
                            ))}
                            
                          
                           
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            </Box>
            </TabPanel>
              </TabContext>
             
          </Box>
        </Box>
      </Box>
      <Modal
        open={isModalDiscreteOpen}
        //onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            height:550,
            bgcolor: "white",
            border: "2px solid #000",
            borderRadius: "8px",
            p: 3,
          }}
        >
          <Typography sx={{ fontSize: 16 }}>
          Discrete Analysis
        </Typography>
          <Button
              variant="contained"
              color="primary"
              onClick={()=>addfpin()}
              disabled = {edit?false:true}
              sx={{mt:2}}
              //disabled={props.editAccess}
            >
              Add New Variable
            </Button>
            <TableContainer component={Paper} sx={{ height: 380,mt:2 }}>
              <Table
                sx={{ minWidth: 495 }}
                aria-label="sticky table"
                stickyHeader
             >
                <TableHead
                  sx={{
                    backgroundColor: Projectcolors.primary,
                  }}
                >
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Variable
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      UOM
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Minimum
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Maximum
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Step
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Runs
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                {fp.length > 0?
                   fp.map((row, i) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {addfprow[i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[0]}
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "variables");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                          sx={{ fontSize: "10px", height: 1, width: 50 }}
                          align="center"
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 100,
                            }}
                            size="small"
                          >
                            <OutlinedInput
                              id="outlined-basic"
                              variant="outlined"
                              placeholder=""
                              defaultValue={row[0]}
                              onChange={(e) => {
                                handleDeclaInp(e, i, "variables");
                              }}
                              sx={{
                                fontSize: "11px",
                                height: "30px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>
                        )}
                        
                        {addfprow[i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[1]}
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "uom");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                          sx={{ fontSize: "10px", height: 1 }}
                          align="center"
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 100,
                            }}
                            size="small"
                          >
                            <OutlinedInput
                              id="outlined-basic"
                              variant="outlined"
                              placeholder=""
                              defaultValue={row[1]}
                              onChange={(e) => {
                                handleDeclaInp(e, i, "uom");
                              }}
                              sx={{
                                fontSize: "11px",
                                height: "30px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>
                        )}
                           {addfprow[i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[2]}
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "desc");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                          sx={{ fontSize: "10px", height: 1, width: 50 }}
                          align="center"
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 100,
                            }}
                            size="small"
                          >
                            <OutlinedInput
                              id="outlined-basic"
                              variant="outlined"
                              placeholder=""
                              defaultValue={row[2]}
                              onChange={(e) => {
                                handleDeclaInp(e, i, "desc");
                              }}
                              sx={{
                                fontSize: "11px",
                                height: "30px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>
                        )}

                        {addfprow[i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[3]}
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "minimum");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[3]}
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "minimum");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        )}
                        {addfprow[i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[4]}
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "maximum");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                          sx={{ fontSize: "10px", height: 1 }}
                          align="center"
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 100,
                            }}
                            size="small"
                          >
                            <OutlinedInput
                              id="outlined-basic"
                              variant="outlined"
                              placeholder=""
                              defaultValue={row[4]}
                              onChange={(e) => {
                                handleDeclaInp(e, i, "maximum");
                              }}
                              sx={{
                                fontSize: "11px",
                                height: "30px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>
                        )}
                         {addfprow[i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[5]}
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "step");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                defaultValue={row[5]}
                                placeholder=""
                                onChange={(e) => {
                                  handleDeclaInp(e, i, "step");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        )}

                      {(addfprow[i] == true && runs.length==0) ? (
                         <TableCell
                         sx={{ fontSize: "10px", height: 1 }}
                         align="center"
                       >
                         0
                       </TableCell>
                          
                        ) : (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            {runs[i]}
                          </TableCell>
                        )}

                        
                        {addfprow[i] == true ? (
                          <TableCell>
                            <DeleteIcon
                              onClick={() => deleteSnap(0, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color: Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell>
                            <DeleteIcon
                            onClick={() => deleteSnap(0, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color: Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    )):null}
              </Table>
            </TableContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={()=>done()}
              disabled = {edit?false:true}
              sx={{mt:2,mb:2}}
              //disabled={props.editAccess}
            >
              Done
            </Button>
          
        </Box>
      </Modal>
      <Modal
        open={isModalStochasticOpen}
        //onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            height:550,
            bgcolor: "white",
            border: "2px solid #000",
            borderRadius: "8px",
            p: 3,
          }}
        >
          <Typography sx={{ fontSize: 16 }}>
          Stochastic Analysis
        </Typography>
          <Button
              variant="contained"
              color="primary"
              onClick={()=>addstofpin()}
              disabled = {edit?false:true}
              sx={{mt:2}}
              //disabled={props.editAccess}
            >
              Add New Variable
            </Button>
            <TableContainer component={Paper} sx={{ height: 380,mt:2 }}>
              <Table
                sx={{ minWidth: 495 }}
                aria-label="sticky table"
                stickyHeader
             >
                <TableHead
                  sx={{
                    backgroundColor: Projectcolors.primary,
                  }}
                >
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Variable
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      UOM
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Minimum
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Maximum
                    </TableCell>
                    
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                {stofp.length > 0?
                   stofp.map((row, i) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {addstofprow[i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[0]}
                                onChange={(e) => {
                                  handleDeclaInpSto(e, i, "variables");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                          sx={{ fontSize: "10px", height: 1, width: 50 }}
                          align="center"
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 100,
                            }}
                            size="small"
                          >
                            <OutlinedInput
                              id="outlined-basic"
                              variant="outlined"
                              placeholder=""
                              defaultValue={row[0]}
                              onChange={(e) => {
                                handleDeclaInpSto(e, i, "variables");
                              }}
                              sx={{
                                fontSize: "11px",
                                height: "30px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>
                        )}
                        
                        {addstofprow[i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[1]}
                                onChange={(e) => {
                                  handleDeclaInpSto(e, i, "uom");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                          sx={{ fontSize: "10px", height: 1 }}
                          align="center"
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 100,
                            }}
                            size="small"
                          >
                            <OutlinedInput
                              id="outlined-basic"
                              variant="outlined"
                              placeholder=""
                              defaultValue={row[1]}
                              onChange={(e) => {
                                handleDeclaInpSto(e, i, "uom");
                              }}
                              sx={{
                                fontSize: "11px",
                                height: "30px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>
                        )}
                           {addstofprow[i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[2]}
                                onChange={(e) => {
                                  handleDeclaInpSto(e, i, "desc");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                          sx={{ fontSize: "10px", height: 1, width: 50 }}
                          align="center"
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 100,
                            }}
                            size="small"
                          >
                            <OutlinedInput
                              id="outlined-basic"
                              variant="outlined"
                              placeholder=""
                              defaultValue={row[2]}
                              onChange={(e) => {
                                handleDeclaInpSto(e, i, "desc");
                              }}
                              sx={{
                                fontSize: "11px",
                                height: "30px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>
                        )}

                        {addstofprow[i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[3]}
                                onChange={(e) => {
                                  handleDeclaInpSto(e, i, "minimum");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[3]}
                                onChange={(e) => {
                                  handleDeclaInpSto(e, i, "minimum");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        )}
                        {addstofprow[i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[4]}
                                onChange={(e) => {
                                  handleDeclaInpSto(e, i, "maximum");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                          sx={{ fontSize: "10px", height: 1 }}
                          align="center"
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 100,
                            }}
                            size="small"
                          >
                            <OutlinedInput
                              id="outlined-basic"
                              variant="outlined"
                              placeholder=""
                              defaultValue={row[4]}
                              onChange={(e) => {
                                handleDeclaInpSto(e, i, "maximum");
                              }}
                              sx={{
                                fontSize: "11px",
                                height: "30px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>
                        )}
                       

                  
                        
                        {addstofprow[i] == true ? (
                          <TableCell>
                            <DeleteIcon
                              onClick={() => deleteStoSnap(0, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color: Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell>
                            <DeleteIcon
                            onClick={() => deleteStoSnap(0, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color:Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    )):null}
              </Table>
            </TableContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={()=>stodone()}
              disabled = {edit?false:true}
              //disabled={props.editAccess}
              sx={{mt:2,mb:2}}
            >
              Done
            </Button>
          
        </Box>
      </Modal>


      <Modal
        open={isModalCalculationsOpen}
        //onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
         <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            height:550,
            bgcolor: "white",
            border: "2px solid #000",
            borderRadius: "8px",
            p: 3,
          }}
        >
          <Typography sx={{ fontSize: 16 }}>
          Dynamic Lean Model Dynamics
        </Typography>
          <Button
              variant="contained"
              color="primary"
              onClick={()=>addcalculationsin()}
              disabled = {edit?false:true}
              sx={{mt:2}}
              //disabled={props.editAccess}
            >
              Add New Variable
            </Button>
            <TableContainer component={Paper} sx={{ height: 380,mt:2 }}>
              <Table
                sx={{ minWidth: 495 }}
                aria-label="sticky table"
                stickyHeader
             >
                <TableHead
                  sx={{
                    backgroundColor: Projectcolors.primary,
                  }}
                >
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        width:130,
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Variable
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        width:130,
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      UOM
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        width:130,
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Formula
                    </TableCell>
          
                    
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: Projectcolors.primary,
                      }}
                    >
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                {newcalcy.length > 0?
                   newcalcy.map((row, i) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {addcalcyrow[i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[0]}
                                onChange={(e) => {
                                  handleNewCalculations(e, i, "variables");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                          sx={{ fontSize: "10px", height: 1, width: 50 }}
                          align="center"
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 100,
                            }}
                            size="small"
                          >
                            <OutlinedInput
                              id="outlined-basic"
                              variant="outlined"
                              placeholder=""
                              defaultValue={row[0]}
                              onChange={(e) => {
                                handleNewCalculations(e, i, "variables");
                              }}
                              sx={{
                                fontSize: "11px",
                                height: "30px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>
                        )}
                        
                        {addcalcyrow[i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1,width: 50 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[1]}
                                onChange={(e) => {
                                  handleNewCalculations(e, i, "uom");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                          sx={{ fontSize: "10px", height: 1 ,width: 50}}
                          align="center"
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 100,
                            }}
                            size="small"
                          >
                            <OutlinedInput
                              id="outlined-basic"
                              variant="outlined"
                              placeholder=""
                              defaultValue={row[1]}
                              onChange={(e) => {
                                handleNewCalculations(e, i, "uom");
                              }}
                              sx={{
                                fontSize: "11px",
                                height: "30px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>
                        )}
                           {addcalcyrow[i] == true ? (
                          <TableCell
                            sx={{ fontSize: "10px", height: 1, width: 50 }}
                            align="center"
                          >
                            <FormControl
                              sx={{
                                float: "right",
                                marginRight: 0,
                                marginTop: 0,
                                marginLeft: 0,
                                width: 100,
                              }}
                              size="small"
                            >
                              <OutlinedInput
                                id="outlined-basic"
                                variant="outlined"
                                placeholder=""
                                defaultValue={row[2]}
                                onChange={(e) => {
                                  handleNewCalculations(e, i, "desc");
                                }}
                                sx={{
                                  fontSize: "11px",
                                  height: "30px",
                                }}
                                size="small"
                              />
                            </FormControl>
                          </TableCell>
                        ) : (
                          <TableCell
                          sx={{ fontSize: "10px", height: 1, width: 50 }}
                          align="center"
                        >
                          <FormControl
                            sx={{
                              float: "right",
                              marginRight: 0,
                              marginTop: 0,
                              marginLeft: 0,
                              width: 100,
                            }}
                            size="small"
                          >
                            <OutlinedInput
                              id="outlined-basic"
                              variant="outlined"
                              placeholder=""
                              defaultValue={row[2]}
                              onChange={(e) => {
                                handleNewCalculations(e, i, "desc");
                              }}
                              sx={{
                                fontSize: "11px",
                                height: "30px",
                              }}
                              size="small"
                            />
                          </FormControl>
                        </TableCell>
                        )}

                        {addcalcyrow[i] == true ? (
                           <TableCell
                           align="center"
                           sx={{ fontSize: "10px", height: 1 ,width: 100}}
                         >
                           <Box sx={{ display: "flex", flexDirection: "row" }}>
                             <Box>
                               <FormControl
                                 sx={{
                                   float: "right",
                                   marginRight: 0,
                                   marginTop: 0,
                                   marginLeft: 0,
                                   width: 190,
                                 }}
                                 size="small"
                               >
                                 <TextField
                                   id="outlined-basic"
                                   variant="outlined"
                                   onChange={(e) => {
                                     handleNewCalculations(e, i, "formula");
                                   }}
                                   multiline
                                   rows={3}
                                   maxRows={30}
                                   placeholder=""
                                   sx={{
                                     fontSize: "11px",
                                   }}
                                   size="small"
                                   value={row[3]}
                                 />


                                    
                               </FormControl>
                             </Box>

                             <Box>
                               
                                 <IconButton onClick={handleInfoClick}>
                                   <InfoIcon fontSize="small" />
                                 </IconButton>
                                 <IconButton onClick={()=>handleMagnifyClick(row[3],i)}>
                                  <SearchIcon />
                                </IconButton>
                                 <Dialog
                                                                        open={isPopupOpen}
                                                                        onClose={handleClosePopup}
                                                                        
                                                                      >
                                                                        
                                                                        <Typography sx={{fontSize:"14px", color: Projectcolors.primary,p:2}}>First Principle Coding</Typography>
                                                                        <TextField
                                                                          id="outlined-basic"
                                                                          variant="outlined"
                                                                          defaultValue={formula}
                                                                          onChange={(e) => {
                                                                            handleNewCalculations(e, formind, "formula");
                                                                          }}
                                                                          multiline
                                                                          rows={10}
                                                                          maxRows={30}
                                                                          placeholder=""
                                                                          fontSize="15px"
                                                                          sx={{
                                                                            width: "400px",
                                                                            fontSize: "15px",
                                                                            padding:"10px"
                                                                          }}
                                                                        />
                                                                      </Dialog>
                               

                             
                             </Box>
                           </Box>
                         </TableCell>
                        ) : (
                          <TableCell
                            align="center"
                            sx={{ fontSize: "10px", height: 1 ,width: 100}}
                          >
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <Box>
                                <FormControl
                                  sx={{
                                    float: "right",
                                    marginRight: 0,
                                    marginTop: 0,
                                    marginLeft: 0,
                                    width: 190,
                                  }}
                                  size="small"
                                >
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    onChange={(e) => {
                                      handleNewCalculations(e, i, "formula");
                                    }}
                                    multiline
                                    rows={3}
                                    maxRows={30}
                                    placeholder=""
                                    sx={{
                                      fontSize: "11px",
                                    }}
                                    size="small"
                                    value={row[4]}
                                  />


                                     
                                </FormControl>
                              </Box>

                              <Box>
                                
                                  <IconButton>
                                    <InfoIcon fontSize="small" />
                                  </IconButton>
                                  <IconButton onClick={()=>handleMagnifyClick(row[4],i)}>
                                  <SearchIcon />
                                </IconButton>
                                 <Dialog
                                                                        open={isPopupOpen}
                                                                        onClose={handleClosePopup}
                                                                        
                                                                      >
                                                                        
                                                                        <Typography sx={{fontSize:"14px", color: Projectcolors.primary,p:2}}>First Principle Coding</Typography>
                                                                        <TextField
                                                                          id="outlined-basic"
                                                                          variant="outlined"
                                                                          defaultValue={formula}
                                                                          onChange={(e) => {
                                                                            handleNewCalculations(e, formind, "formula");
                                                                          }}
                                                                          multiline
                                                                          rows={10}
                                                                          maxRows={30}
                                                                          placeholder=""
                                                                          fontSize="15px"
                                                                          sx={{
                                                                            width: "400px",
                                                                            fontSize: "15px",
                                                                            padding:"10px"
                                                                          }}
                                                                        />
                                                                      </Dialog>
                               
                                

                               
                              </Box>
                            </Box>
                          </TableCell>
                        )}
                       
                  
                        
                        {addcalcyrow[i] == true ? (
                          <TableCell sx={{width: 50}}>
                            <DeleteIcon
                              onClick={() => deleteCalcySnap(0, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color: Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell sx={{width: 50}}>
                            <DeleteIcon
                            onClick={() => deleteCalcySnap(0, i)}
                              sx={{
                                display: "inline",
                                fontSize: 15,
                                float: "right",
                                marginTop: 1,
                                marginRight: 2,
                                color:Projectcolors.primary,
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    )):null}
              </Table>
            </TableContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={()=>calculationsdone()}
              disabled = {edit?false:true}
              //disabled={props.editAccess}
              sx={{mt:2,mb:2}}
            >
              Done
            </Button>
          
        </Box>
        </Modal>

      {isLoading ? <GlobalLoader /> : null}
      {isdiscreteLoading ? <GlobalLoader /> : null}
    </div>
  );
}

export default AnalysisLandingPage;
